import { useState } from 'react';
import { usePresentationId } from '../../../presentation/hooks/usePresentationId.ts';
import { useInvalidatePresentationQuery } from '../../../presentation/hooks/usePresentation.ts';
import { useNavigate } from 'react-router-dom';
import { createStaticContent } from '../../../presentation/service/ElementService.ts';
import { StaticContentTypeT } from '../../../../types/StaticContent.ts';
import { translate } from '../../../../translate';
import { Flex, SimpleGrid } from '@chakra-ui/react';
import { NewElementHeader } from '../../../commons/components/NewElementHeader';
import { StaticContentPickerIconBox } from './StaticContentPickerIconBox.tsx';
import isEmpty from 'lodash/isEmpty';
import { NewStaticContentInput, NewStaticContentInputFormValues } from './NewStaticContentInput.tsx';

interface Props {
	onGoBack: () => void;
}

const supportedTypes = [
	StaticContentTypeT.COVER,
	StaticContentTypeT.CALL_TO_ACTION,
	StaticContentTypeT.STATEMENT,
	StaticContentTypeT.QUOTE,
	StaticContentTypeT.LIST,
	StaticContentTypeT.EMBED,
];

export function StaticContentSelector({ onGoBack }: Props) {
	const presentationId = usePresentationId();
	const invalidatePresentationQuery = useInvalidatePresentationQuery(presentationId);
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);

	const [selectedContentType, setSelectedContentType] = useState<StaticContentTypeT>();
	const [newContentInput, setNewContentInput] = useState<NewStaticContentInputFormValues>();

	const handleOnGoBack = () => {
		setSelectedContentType(undefined);
	};

	const onSubmit = async (values: NewStaticContentInputFormValues) => {
		if (!isLoading && selectedContentType) {
			setIsLoading(true);
			setNewContentInput(values);
			const newSlide = await createStaticContent(presentationId, {
				title: values.title,
				type: selectedContentType,
			});
			await invalidatePresentationQuery();
			navigate(`/presentations/${presentationId}/slides/${newSlide.slideId}/new`);
		}
	};

	return (
		<>
			{!selectedContentType ? (
				<Flex flexDirection='column' alignItems='center'>
					<NewElementHeader title={translate('Which type of content slide do you want to create?')} onGoBack={onGoBack} />
					<Flex flexDirection='column' w='100%'>
						<Flex justifyContent='center' alignItems='center' flexDirection='column'>
							<SimpleGrid columns={4} gap={8}>
								{supportedTypes.map((type) => (
									<StaticContentPickerIconBox key={type} type={type} onSelect={setSelectedContentType} />
								))}
							</SimpleGrid>
						</Flex>
					</Flex>
				</Flex>
			) : null}
			{selectedContentType && isEmpty(newContentInput) ? <NewStaticContentInput onSubmit={onSubmit} onGoBack={handleOnGoBack} /> : null}
		</>
	);
}
