import { switchAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(switchAnatomy.keys);

const baseStyle = definePartsStyle({
	// define the part you're going to style
	container: {
		// ...
	},
	thumb: {
		_checked: {
			bg: 'brand',
		},
	},
	track: {
		bg: 'colorScheme.200',
		_checked: {
			bg: 'colorScheme.200',
		},
	},
});

export const switchTheme = defineMultiStyleConfig({ baseStyle });
