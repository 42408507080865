import React from 'react';
import { BasicElementT } from '../../../../types/Presentation.ts';
import { StaticContentTypeT } from '../../../../types/StaticContent.ts';
import { useStaticContent } from '../../hooks/useStaticContent.ts';
import { Loading } from '../../../commons/components/Loading';
import { StaticContentCover } from '../StaticContentCover';
import { StaticContentStatement } from '../StaticContentStatement';
import { StaticContentQuote } from '../StaticContentQuote';
import { StaticContentEmbed } from '../StaticContentEmbed';
import { StaticContentList } from '../StaticContentList';
import { StaticContentCallToAction } from '../StaticContentCallToAction';

interface Props {
	presentationId: string;
	element: BasicElementT;
}

export const StaticContent: React.FC<Props> = ({ presentationId, element }) => {
	const configurationType = element.type as StaticContentTypeT;
	const { isLoading, data } = useStaticContent(presentationId, element.id);

	if (isLoading && !data) {
		return <Loading />;
	}

	if (!data) {
		return null;
	}

	if (configurationType === StaticContentTypeT.COVER) {
		return <StaticContentCover key={data.id} presentationId={presentationId} content={data} />;
	} else if (configurationType === StaticContentTypeT.STATEMENT) {
		return <StaticContentStatement key={data.id} presentationId={presentationId} content={data} />;
	} else if (configurationType === StaticContentTypeT.QUOTE) {
		return <StaticContentQuote key={data.id} presentationId={presentationId} content={data} />;
	} else if (configurationType === StaticContentTypeT.EMBED) {
		return <StaticContentEmbed key={data.id} presentationId={presentationId} content={data} />;
	} else if (configurationType === StaticContentTypeT.LIST) {
		return <StaticContentList key={data.id} presentationId={presentationId} content={data} />;
	} else if (configurationType === StaticContentTypeT.CALL_TO_ACTION) {
		return <StaticContentCallToAction key={data.id} presentationId={presentationId} content={data} />;
	}

	return null;
};
