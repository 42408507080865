import { VisualizationTypeT } from '../../../../../../types/Visualization.ts';
import { getIconForElement, mapElementTypeToWords } from '../../../../../../util/InteractionUtils.ts';
import { ElementTypeT } from '../../../../../../types/Presentation.ts';
import { IconBox } from '../../../../../commons/components/IconBox';

interface Props {
	visualizationType: VisualizationTypeT;
	onClick: (visualizationType: VisualizationTypeT) => void;
}

export const VisualizationIconBox = ({ visualizationType, onClick }: Props) => {
	const visualizationIcons = getIconForElement(ElementTypeT.VISUALIZATION);
	const handleOnClick = () => onClick(visualizationType);
	return <IconBox label={mapElementTypeToWords(visualizationType)} iconImageSrc={visualizationIcons(visualizationType)} onClick={handleOnClick} />;
};
