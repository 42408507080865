import React, { useEffect, useRef, useState } from 'react';
import { chakra, FlexboxProps, LayoutProps } from '@chakra-ui/react';
import { BasicSlideT } from '../../../../types/Presentation.ts';
import eq from 'lodash/eq';
import isEmpty from 'lodash/isEmpty';
import { SlideBox } from './_/SlideBox';
import { Reorder } from 'framer-motion';
import { AUTO_SAVE_DEBOUNCE_TIME } from '../../../../types/Constants.ts';
import debounce from 'lodash/debounce';
import { updatePresentationSlidesOrder, UpdateSlideOrder } from '../../service/PresentationService.ts';
import { useInvalidatePresentationQuery } from '../../hooks/usePresentation.ts';

const List = chakra(Reorder.Group);
const ListItem = chakra(Reorder.Item);

interface Props {
	presentationId: string;
	slides: BasicSlideT[];
	selectedSlide?: BasicSlideT;
	onSelectSlide: (slide: BasicSlideT) => void;
}

const SCROLL_SPEED = 10;
const EDGE_THRESHOLD = 80;

export const Sidebar: React.FC<Props & FlexboxProps & LayoutProps> = ({ presentationId, slides, selectedSlide, onSelectSlide }) => {
	const listRef = useRef<HTMLDivElement>(null);
	const [isDragging, setIsDragging] = useState(false);
	const [scrollDirection, setScrollDirection] = useState<'up' | 'down' | null>(null);
	const invalidatePresentation = useInvalidatePresentationQuery(presentationId);
	const [order, setOrder] = useState(() => slides.map((slide) => slide.id));

	useEffect(() => {
		setOrder(slides.map((slide) => slide.id));
	}, [slides]);

	useEffect(() => {
		if (!isDragging || !scrollDirection) return;

		const container = listRef.current;
		if (!container) return;

		let animationFrameId: number;

		const scroll = () => {
			if (scrollDirection === 'up') {
				container.scrollTop -= SCROLL_SPEED;
			} else if (scrollDirection === 'down') {
				container.scrollTop += SCROLL_SPEED;
			}

			animationFrameId = requestAnimationFrame(scroll);
		};

		scroll();

		return () => {
			cancelAnimationFrame(animationFrameId);
		};
	}, [isDragging, scrollDirection]);

	const handleDrag = (event: MouseEvent) => {
		const container = listRef.current;
		if (!container) return;

		const containerRect = container.getBoundingClientRect();
		const cursorY = event.clientY;

		if (cursorY - containerRect.top < EDGE_THRESHOLD) {
			setScrollDirection('up');
		} else if (containerRect.bottom - cursorY < EDGE_THRESHOLD) {
			setScrollDirection('down');
		} else {
			setScrollDirection(null);
		}
	};

	const handleDragEnd = () => {
		const changes: UpdateSlideOrder[] = [];
		let hasChanges = false;
		order.forEach((id, newIndex) => {
			const oldIndex = slides.findIndex((slide) => slide.id === id);
			changes.push({ slideId: id, newIndex });
			if (oldIndex !== newIndex) {
				hasChanges = true;
			}
		});
		if (hasChanges && changes.length > 0) {
			updateOrderInAPI(changes);
		}
	};

	const updateOrderInAPI = debounce(async (changes: UpdateSlideOrder[]) => {
		await updatePresentationSlidesOrder(presentationId, changes);
		/*
			Useful for easily updating slide indexes in local state,
			but also causes ElementPreview to reload due to a rerender (when fresh data arrives).
			If we don't update the presentation's slide indexes after reordering, it will break the ElementPreview by showing the previous slide.
			This behavior is triggered by updating an ElementConfiguration.
		 */
		invalidatePresentation();
	}, AUTO_SAVE_DEBOUNCE_TIME);

	if (isEmpty(slides)) {
		return null;
	}

	return (
		<List maxH='80svh' ref={listRef} values={order} onReorder={(newOrder: unknown[]) => setOrder(newOrder as string[])} layoutScroll={true} axis='y' overflowY='auto'>
			{order
				.map((id) => slides.find((slide) => slide.id === id))
				.map((slide) =>
					slide ? (
						<ListItem
							key={slide.id}
							value={slide.id}
							cursor='grab'
							whileTap={{ cursor: 'grabbing', scale: 1.1 }}
							onDragStart={() => setIsDragging(true)}
							onDragEnd={() => {
								handleDragEnd();
								setIsDragging(false);
								setScrollDirection(null);
							}}
							onDrag={(event) => handleDrag(event as MouseEvent)}
						>
							<SlideBox slide={slide} onSelectSlide={onSelectSlide} isSelected={eq(selectedSlide?.id, slide.id)} />
						</ListItem>
					) : null
				)}
		</List>
	);
};
