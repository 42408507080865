import React, { useEffect } from 'react';
import { Input } from '@chakra-ui/react';
import debounce from 'lodash/debounce';
import { AUTO_SAVE_DEBOUNCE_TIME } from '../../../../types/Constants.ts';

interface Props {
	onInputChange: (value: string) => void;
}

export const EntryListHeader: React.FC<Props> = ({ onInputChange }) => {
	const handleOnInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		onInputChange(event.target.value);
	};
	const debouncedInputChangeHandler = debounce(handleOnInputChange, AUTO_SAVE_DEBOUNCE_TIME);

	useEffect(() => {
		return () => {
			debouncedInputChangeHandler.cancel();
		};
	}, []);

	return <Input placeholder='Search' onChange={debouncedInputChangeHandler} />;
};
