export const translations = {
	en: {
		translation: {
			Create: undefined,
			Creating: undefined,
			Presentation: undefined,
			'Answer Required': undefined,
			'Whats the main topic of your presentation?': "What's the main topic of your presentation?",
			'You can change your presentation title later': undefined,
			'Type your presentation title here': undefined,
			'New presentation': undefined,
			'Log in to your account': undefined,
			'Reset your password': undefined,
			'Sign in': undefined,
			Password: undefined,
			'Forgot password?': undefined,
			'By using Questiory you agree to abide by our': undefined,
			'Terms of service': undefined,
			'What type of slide do you want to create?': undefined,
			Interaction: undefined,
			'Inquire and get the information you need': undefined,
			Visualization: undefined,
			'Bring your data to life with visualizations': undefined,
			Content: undefined,
			'Tell something to your audience': undefined,
			'What type of interaction do you want to create?': undefined,
			Number: undefined,
			Categorized: undefined,
			Valuation: undefined,
			Image: undefined,
			Iconic: undefined,
			Drawing: undefined,
			'Multiple Choice': undefined,
			Link: undefined,
			'Rotating Words': undefined,
			'Word Cloud': undefined,
			'Bar Chart': undefined,
			'Pie Chart': undefined,
			Gauge: undefined,
			Radar: undefined,
			Pictogram: undefined,
			Wheel: undefined,
			Nodes: undefined,
			Blocks: undefined,
			Cards: undefined,
			'Flip Cards': undefined,
			Comic: undefined,
			'Simple Math': undefined,
			'Static Content': undefined,
			'How would you like to start your visualization?': undefined,
			Existing: undefined,
			New: undefined,
			'View and use data from an existing Interaction': undefined,
			'Create a new Interaction from scratch': undefined,
			'Using data from an existing interaction': undefined,
			'Use data captured externally': undefined,
			'Which Interaction do you want to visualize?': undefined,
			'Start typing...': undefined,
			'No interactions to visualize yet. Begin by creating or using data from another Presentation': undefined,
			'How do you want to capture the data for this visualization?': undefined,
			'What type of visualization do you want to create?': undefined,
			'Your presentations': undefined,
			Title: undefined,
			'Last modified': undefined,
			Actions: undefined,
			'Play presentation': undefined,
			Edit: undefined,
			Delete: undefined,
			'Interaction title': undefined,
			'Type your interaction title here': undefined,
			'This field is required': undefined,
			'Title must be at least 3 characters long': undefined,
			Next: undefined,
			'Start your presentation': undefined,
			'Click New Slide to begin': undefined,
			'New Slide': undefined,
			Play: undefined,
			'Presentation settings': undefined,
			Close: undefined,
			'Hide Progress Bar': undefined,
			'Hide Navigation Arrows': undefined,
			'Selected theme': 'Selected theme:',
			Questiory: undefined,
			'Manage your themes': undefined,
			'Slide Preview (View only)': undefined,
			'View slide': undefined,
			'Manage entries for interaction': undefined,
			Description: undefined,
			'Add a description': undefined,
			Categories: undefined,
			Settings: undefined,
			Design: undefined,
			'Hide slide': undefined,
			'Hide slide title': undefined,
			'Image Background': undefined,
			Select: undefined,
			Change: undefined,
			'Moderate answers': undefined,
			Prev: undefined,
			'There are no entries to moderate yet': undefined,
			'Delete all': undefined,
			'This will permanently delete all data captured for the Interaction': undefined,
			'Are you sure?': 'Are you sure?',
			Yes: 'Delete',
			No: 'Cancel',
			'Interaction you are visualizing': undefined,
			'You are about to delete': undefined,
			interaction: undefined,
			visualization: undefined,
			'Error fetching from Unsplash': undefined,
			'Error fetching settings': undefined,
			'Error fetching themes': undefined,
			'Short answers': undefined,
			'Long answers': undefined,
			'Has selected answers': undefined,
			'Selected answers reference': undefined,
			'Add accepted entry': undefined,
			'Floating legend': undefined,
			'Footer legend': undefined,
			Options: undefined,
			'Multiple responses': undefined,
			'New theme': undefined,
			'Editing your theme': undefined,
			'Creating a new theme': undefined,
			Themes: undefined,
			'No themes yet': undefined,
			'Create a new theme to start': undefined,
			'Are you sure? This action cannot be reversed': undefined,
			'Yes, delete': undefined,
			'Error fetching Settings': undefined,
			'Select presentation': undefined,
			'Maximum number of answers': undefined,
			'Search image': undefined,
			'Unsplash Photo': undefined,
			'Self-hosted image': undefined,
			'Type image link': undefined,
			Duplicate: undefined,
			Copy: undefined,
			Copied: undefined,
			'Max columns': undefined,
			'Error fetching collections': undefined,
			Backgrounds: undefined,
			'The following associated visualizations will be removed': undefined,
			'Dont have an account?': undefined,
			'Request access here': undefined,
			Share: undefined,
			Embed: undefined,
			Statements: undefined,
			'Open links in new tab': undefined,
			'Enter a url you want to embed': undefined,
			'Which type of content slide do you want to create?': undefined,
			Quote: undefined,
			Cover: undefined,
			Statement: undefined,
			List: undefined,
			'Call to action': undefined,
			Buttons: undefined,
			'Add Other': undefined,
		},
	},
	es: {
		translation: {
			Create: 'Crear',
			Creating: 'Creando',
			Presentation: 'Presentación',
			'Answer Required': 'Respuesta requerida',
			'Whats the main topic of your presentation?': 'Cuál es el tema principal de esta presentación?',
			'You can change your presentation title later': 'Podrás cambiar el título de tu presentación luego',
			'Type your presentation title here': 'Ingresa el título de presentación',
			'New presentation': 'Nueva presentación',
			'Log in to your account': 'Ingresa a tu cuenta',
			'Reset your password': 'Cambia tu contraseña',
			'Sign in': 'Acceder',
			'Forgot password?': 'Olvidaste tu contraseña?',
			Password: 'Contraseña',
			'By using Questiory you agree to abide by our': 'Usando Questiory aceptas nuestros',
			'Terms of service': 'Términos de servicio',
			'What type of slide do you want to create?': '¿Qué tipo de slide quieres crear?',
			Interaction: 'Interacción',
			'Inquire and get the information you need': 'Crea preguntas y actividades',
			Visualization: 'Visualización',
			'Bring your data to life with visualizations': 'Visualiza las respuestas de tu audiencia',
			'Tell something to your audience': 'Cuenta algo a tu audiencia',
			'What type of interaction do you want to create?': '¿Qué tipo de interacción quieres crear?',
			Number: 'Respuesta numérica',
			Categorized: 'Respuestas categorizadas',
			Valuation: 'Valoración',
			Image: 'Participación con imagen',
			Iconic: 'Respuesta icónica',
			Drawing: 'Dibujo',
			'Multiple Choice': 'Pregunta de opción múltiple',
			Link: 'Hipervínculo',
			'Rotating Words': 'Palabras rotativas',
			'Word Cloud': 'Nube de palabras',
			'Bar Chart': 'Gráfico de barras',
			'Pie Chart': 'Gráfico circular',
			Gauge: 'Gráfico de líneas circulares',
			Radar: 'Gráfico de radar',
			Pictogram: 'Pictograma',
			Wheel: 'Gráfico de rueda',
			Nodes: 'Diagrama de nodos',
			Blocks: 'Bloques',
			Cards: 'Tarjetas',
			'Flip Cards': undefined,
			Comic: undefined,
			'Simple Math': 'Cálculo simple',
			'Static Content': 'Contenido estático',
			'How would you like to start your visualization?': '¿Cómo quieres comenzar esta visualización?',
			Existing: 'Existente',
			New: 'Nueva',
			'View and use data from an existing Interaction': 'Visualizar datos de una de mis interacciones',
			'Create a new Interaction from scratch': 'Crear una nueva interacción desde cero',
			'Using data from an existing interaction': 'Usar datos de una interacción existente',
			'Use data captured externally': 'Utilizar datos capturados en otra presentación',
			'Which Interaction do you want to visualize?': '¿Qué interacción quieres visualizar?',
			'Start typing...': 'Comienza a escribir...',
			'No interactions to visualize yet. Begin by creating or using data from another Presentation':
				'Aún no hay interacciones para visualizar. Comienza creando o utilizando datos de otra presentación.',
			'How do you want to capture the data for this visualization?': '¿Cómo desea capturar los datos para esta visualización?',
			'What type of visualization do you want to create?': '¿Qué tipo de visualización quieres crear?',
			'Your presentations': 'Tus presentaciones',
			Title: 'Título',
			'Last modified': 'Modificado',
			Actions: 'Ver acciones',
			'Play presentation': 'Ver presentación',
			Edit: 'Editar',
			Delete: 'Eliminar',
			'Interaction title': 'Agrega un título a la interacción',
			'Type your interaction title here': 'Escribe un título o pregunta (puedes cambiarlo luego)',
			'This field is required': 'Esta campo es requerido',
			'Title must be at least 3 characters long': 'El título debe tener al menos 3 caracteres',
			Prev: 'Anterior',
			Next: 'Siguiente',
			'Start your presentation': 'Comienza tu presentación',
			'Click New Slide to begin': 'Haz click en NUEVO SLIDE para empezar',
			'New Slide': 'Nuevo slide',
			Play: 'Ver',
			'Presentation settings': 'Ajustes de presentación',
			Close: 'Cerrar',
			'Hide Progress Bar': 'Ocultar barra de progreso',
			'Hide Navigation Arrows': 'Ocultar flechas',
			Questiory: undefined,
			'Selected theme': 'Template seleccionado:',
			'Manage your themes': 'Mis templates',
			'Slide Preview (View only)': 'Previsualización de slide (solo lectura)',
			'View slide': 'Ver slide',
			'Manage entries for interaction': 'Moderar respuestas',
			Description: 'Subtitulo',
			'Add a description': 'Agrega un subtitulo',
			Categories: 'Categorias',
			Content: 'Contenido',
			Settings: 'Ajustes',
			Design: 'Diseño',
			'Hide slide': 'Ocultar slide',
			'Hide slide title': 'Ocultar título de slide',
			'Image Background': 'Agregar imagen de fondo',
			Select: 'Seleccionar',
			Change: 'Cambiar',
			'Moderate answers': 'Moderar respuestas',
			'There are no entries to moderate yet': 'Todavía no hay respuestas',
			'Delete all': 'Eliminar respuestas',
			'This will permanently delete all data captured for the Interaction': 'Esta acción va a eliminar permanentemente todas las respuestas a esta interacción',
			'Are you sure?': '¿Estás seguro?',
			Yes: 'Eliminar',
			No: 'Cancelar',
			'Interaction you are visualizing': 'Estás visualizando la interacción:',
			'You are about to delete': 'Estás por eliminar',
			interaction: 'interacción',
			visualization: 'visualización',
			'Error fetching from Unsplash': 'Hubo un error obteniendo imágenes desde Unsplash',
			'Error fetching settings': 'Hubo un error obteniendo configuraciones',
			'Error fetching themes': 'Hubo un error obteniendo templates',
			'Short answers': 'Respuestas breves',
			'Long answers': 'Respuestas largas',
			'Has selected answers': 'Seleccionar respuestas',
			'Selected answers reference': 'Referencia para tus respuestas seleccionadas',
			'Add accepted entry': 'Agrega una respuesta aceptada',
			'Floating legend': 'Leyenda flotante',
			'Footer legend': 'Leyenda inferior',
			Options: 'Opciones',
			'Multiple responses': 'Respuesta múltiple',
			'New theme': 'Nuevo template',
			'Editing your theme': 'Editando template',
			'Creating a new theme': 'Creando nuevo template',
			Themes: 'Templates',
			'You dont have any themes': 'Aun no tienes templates',
			'Create a new theme to start': 'Comienza creando uno nuevo',
			'No themes yet': 'Aun no tienes templates',
			'Are you sure? This action cannot be reversed': 'Lo quieres hacer? Esta acción no se puede deshacer',
			'Yes, delete': 'Si, borrar',
			'Error fetching Settings': 'Error obteniendo settings',
			'Select presentation': 'Seleccionar presentación',
			'Maximum number of answers': 'Máximo número de respuestas',
			'Search image': 'Buscar imagen',
			'Unsplash Photo': 'Fotos de Unsplash',
			'Self-hosted image': 'Url de imagen',
			'Type image link': 'Ingresar link',
			Duplicate: 'Duplicar',
			Copy: 'Copiar',
			Copied: 'Copiado',
			'Max columns': 'Columnas Max',
			'Error fetching collections': 'Error obteniendo colecciones',
			Backgrounds: 'Fondos',
			'The following associated visualizations will be removed': 'Las siguientes visualizaciones van a ser borradas',
			'Dont have an account?': '¿No tienes una cuenta?',
			'Request access here': 'Solicita acceso aquí',
			Share: 'Compartir',
			'Open links in new tab': 'Abrir links en nueva ventana',
			'Enter a url you want to embed': 'Ingresa una url',
			'Which type of content slide do you want to create?': '¿Qué tipo de diapositiva de contenido quieres crear?',
			Embed: 'Objeto multimedia',
			Quote: 'Cita',
			Cover: 'Portada',
			Statement: 'Enunciado',
			List: 'Listado',
			'Call to action': 'Botones',
			Buttons: 'Botones',
			'Add Other': 'Agregar otro',
		},
	},
} as const;
