import { Td, Tr } from '@chakra-ui/react';
import { LinkEntry } from '../../../model/entry.ts';
import { ModerateEntryInput } from '../../ModerateEntryInput';
import { BasicPresentationT } from '../../../../../types/Presentation.ts';
import { InteractionT } from '../../../../../types/Interaction.ts';

interface Props {
	presentationId: BasicPresentationT['id'];
	interactionId: InteractionT['id'];
	entries: LinkEntry[];
}

export const LinkTableRow = ({ presentationId, interactionId, entries }: Props) => {
	return (
		<>
			{entries.map((entry) => (
				<Tr key={entry.id}>
					<Td>
						<ModerateEntryInput presentationId={presentationId} interactionId={interactionId} entry={entry} />
					</Td>
					<Td>{entry.link}</Td>
					<Td>{entry.heading}</Td>
					<Td>{entry.description}</Td>
				</Tr>
			))}
		</>
	);
};
