import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, useDisclosure } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { IoAddSharp } from 'react-icons/io5';
import { useLocation } from 'react-router-dom';
import { EmptySlide } from '../EmptySlide';
import { translate } from '../../../../translate';

interface Props {
	defaultIsOpen?: boolean;
}

export const AddNewSlide: React.FC<Props> = ({ defaultIsOpen = false }) => {
	const { pathname } = useLocation();
	const { isOpen, onOpen, onClose } = useDisclosure({
		defaultIsOpen,
	});

	useEffect(() => {
		if (!defaultIsOpen) {
			onClose();
		}
	}, [defaultIsOpen, pathname, onClose]);

	return (
		<>
			<Button my={1} aria-label='new slide' variant='submit' onClick={onOpen} rightIcon={<IoAddSharp />}>
				{translate('New Slide')}
			</Button>
			<Modal isOpen={isOpen} size='full' scrollBehavior='inside' isCentered onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalCloseButton size='lg' />
					<ModalBody padding={10} backgroundColor='background'>
						<EmptySlide key='empty' />
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
};
