import { BorderProps, FlexboxProps, Image, Text } from '@chakra-ui/react';
import React from 'react';
import { Card } from '../Card';

interface Props {
	iconImageSrc: string;
	label: string;
	onClick?: () => void;
	description?: string;
}

const SIZE = 250;

export const IconBox: React.FC<Props & FlexboxProps & BorderProps> = ({ iconImageSrc, label, description, ...props }) => {
	return (
		<Card
			h={SIZE}
			w={SIZE}
			backgroundColor='white'
			justifyContent='center'
			alignItems='center'
			flexDirection='column'
			borderRadius='24'
			_hover={{ cursor: 'pointer', boxShadow: '2xl' }}
			boxShadow='lg'
			{...props}
		>
			<Image src={iconImageSrc} alt={label} boxSize='32' />
			<Text fontSize='2xl' mt={2} lineHeight='2.2rem'>
				{label}
			</Text>
			{description ? (
				<Text fontSize='lg' align='center'>
					{description}
				</Text>
			) : null}
		</Card>
	);
};

IconBox.displayName = 'IconBox';
