import ApiClient from '../../../apiClient';
import {
	ContentConfigurationCallToActionT,
	ContentConfigurationCoverT,
	ContentConfigurationEmbedT,
	ContentConfigurationListT,
	ContentConfigurationQuoteT,
	ContentConfigurationStatementT,
	StaticContentT,
} from '../../../types/StaticContent.ts';

export const getStaticContentDetails = async (presentationId: string, staticContentId: string): Promise<StaticContentT> => {
	return (await ApiClient.getInstance().get<StaticContentT>(`/author-app/presentations/${presentationId}/static-content/${staticContentId}`)).data;
};

// export type UpdateStaticContentInput = Pick<StaticContentT, 'title' | 'description' | 'content'>;
// export const saveStaticContent = async (presentationId: string, staticContentId: string, input: UpdateStaticContentInput): Promise<void> => {
// 	return (await ApiClient.getInstance().put(`/author-app/presentations/${presentationId}/static-content/${staticContentId}`, input)).data;
// };

export const deleteStaticContent = async (presentationId: string, staticContentId: string): Promise<void> => {
	await ApiClient.getInstance().delete(`/author-app/presentations/${presentationId}/static-content/${staticContentId}`);
};

/**
 * Types
 */
export const saveContentCover = async (presentationId: string, staticContentId: string, input: ContentConfigurationCoverT): Promise<void> => {
	await ApiClient.getInstance().put(`/author-app/presentations/${presentationId}/static-content/${staticContentId}/cover`, input);
};
export const saveContentEmbed = async (presentationId: string, staticContentId: string, input: ContentConfigurationEmbedT): Promise<void> => {
	await ApiClient.getInstance().put(`/author-app/presentations/${presentationId}/static-content/${staticContentId}/embed`, input);
};
export const saveContentStatement = async (presentationId: string, staticContentId: string, input: ContentConfigurationStatementT): Promise<void> => {
	await ApiClient.getInstance().put(`/author-app/presentations/${presentationId}/static-content/${staticContentId}/statement`, input);
};
export const saveContentQuote = async (presentationId: string, staticContentId: string, input: ContentConfigurationQuoteT): Promise<void> => {
	await ApiClient.getInstance().put(`/author-app/presentations/${presentationId}/static-content/${staticContentId}/quote`, input);
};
export const saveContentList = async (presentationId: string, staticContentId: string, input: ContentConfigurationListT): Promise<void> => {
	await ApiClient.getInstance().put(`/author-app/presentations/${presentationId}/static-content/${staticContentId}/list`, input);
};
export const saveContentCallToAction = async (presentationId: string, staticContentId: string, input: ContentConfigurationCallToActionT): Promise<void> => {
	await ApiClient.getInstance().put(`/author-app/presentations/${presentationId}/static-content/${staticContentId}/call-to-action`, input);
};
