import React, { useState } from 'react';
import { ImagePicker } from '../../../../../commons/components/ImagePicker';
import { Alert, AlertIcon, AlertTitle, Button, Flex, FormLabel, Image, Switch, useDisclosure } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { updateSlideImageBackground } from '../../../../service/PresentationService.ts';
import { ElementSlideT } from '../../../../../../types/Slide.ts';
import { refreshElementPreview } from '../../../ElementPreview/ElementPreview.tsx';
import { translate } from '../../../../../../translate';

interface Props {
	presentationId: string;
	slide: ElementSlideT;
}

export const SlideBackgroundImageInput: React.FC<Props> = ({ presentationId, slide }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [selectedImage, setSelectedImage] = useState<string | undefined>(slide.imageBackgroundUrl);
	const [error, setError] = useState<Error | undefined>();
	const [isImageBackgroundOn, setIsImageBackgroundOn] = useState<boolean>(!isEmpty(slide.imageBackgroundUrl));

	const handleOnSelectImage = async (imageUrl?: string) => {
		try {
			setSelectedImage(imageUrl);
			await updateSlideImageBackground(presentationId, slide.id, {
				imageBackgroundUrl: imageUrl,
			});
			refreshElementPreview();
		} catch (e) {
			setError(e as Error);
		}
	};

	const handleSwitchChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		setIsImageBackgroundOn(event.target.checked);
		if (!event.target.checked) {
			await handleOnSelectImage(undefined);
		}
	};

	return (
		<>
			<Flex id='slide-background' gap='2' flexDirection='column'>
				<Flex>
					<Switch size='lg' id='image-background-switch' isChecked={isImageBackgroundOn} onChange={handleSwitchChange} />
					<FormLabel htmlFor='image-background-switch' fontSize='lg' mb='0' ml='2'>
						{translate('Image Background')}
					</FormLabel>
				</Flex>

				{isImageBackgroundOn && (
					<Flex alignItems='center' flexDirection='column'>
						{!isEmpty(selectedImage) && <Image src={selectedImage} alt='Preview' boxSize='80' fit='contain' />}
						<Button size='sm' variant='brand' my={0} onClick={onOpen}>
							{isEmpty(selectedImage) ? translate('Select') : translate('Change')}
						</Button>
					</Flex>
				)}
			</Flex>

			{!isEmpty(error) && (
				<Alert status='error' mt='2'>
					<AlertIcon />
					<AlertTitle mr='2'>Error saving slide image background</AlertTitle>
				</Alert>
			)}
			<ImagePicker isOpen={isOpen} onClose={onClose} onChange={handleOnSelectImage} />
		</>
	);
};
