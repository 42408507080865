import { useEffect } from 'react';

type Params = {
	id: string;
	src: string;
	onLoad: () => void;
};

export const useExternalScript = ({ id, src, onLoad }: Params) => {
	useEffect(() => {
		let script = document.getElementById(id) as HTMLScriptElement | null;
		if (!script) {
			script = document.createElement('script');
			script.id = id;
			script.src = src;
			script.async = true;
			document.body.appendChild(script);
			document.getElementById(script.id)?.addEventListener('load', () => {
				onLoad();
			});
		}

		return () => {
			if (script) {
				document.body.removeChild(script);
			}
		};
	}, [id, src, onLoad]);
};
