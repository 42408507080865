import { useQuery } from '@tanstack/react-query';
import { unsplashClient, UnsplashSearchParams } from '../api/UnsplashApi';

const key = (query: UnsplashSearchParams) => ['unsplash', query];

export const useUnsplashSearch = (params: UnsplashSearchParams) => {
	return useQuery({
		queryKey: key(params),
		queryFn: () => unsplashClient.searchPhotos({ ...params }),
		staleTime: Infinity,
		enabled: params.query.length >= 3,
		refetchOnWindowFocus: false,
	});
};
