import React from 'react';
import { InteractionTypeT } from '../../../../../../types/Interaction.ts';
import { getIconForElement, mapElementTypeToWords } from '../../../../../../util/InteractionUtils.ts';
import { ElementTypeT } from '../../../../../../types/Presentation.ts';
import { IconBox } from '../../../../../commons/components/IconBox';

interface Props {
	interactionType: InteractionTypeT;
	onClick: (interactionType: InteractionTypeT) => void;
}

export const InteractionIconBox: React.FC<Props> = ({ interactionType, onClick }) => {
	const interactionIcons = getIconForElement(ElementTypeT.INTERACTION);
	const handleOnClick = () => {
		onClick(interactionType);
	};
	return <IconBox label={mapElementTypeToWords(interactionType)} iconImageSrc={interactionIcons(interactionType)} onClick={handleOnClick} />;
};
