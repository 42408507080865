import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ThemeT } from '../model/ThemeT.ts';
import { upsertTheme } from '../service/ThemingService.ts';
import { allThemesKey } from './useAllThemes.ts';
import { themeKey } from './useTheme.ts';

export const useUpsertTheme = () => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: (input: ThemeT) => upsertTheme(input),
		onSuccess: async (data, variables, context) => {
			console.debug(data, context);
			await queryClient.invalidateQueries({
				queryKey: allThemesKey(),
			});
			await queryClient.invalidateQueries({
				queryKey: themeKey(variables.id!),
			});
		},
	});
};
