import React, { useEffect } from 'react';
import { RotatingWordsConfigurationT, VisualizationT } from '../../../../types/Visualization.ts';
import { useInvalidatePresentationQuery } from '../../../presentation/hooks/usePresentation.ts';
import { SubmitHandler, useForm } from 'react-hook-form';
import { saveRotatingWordsVisualization } from '../../service/VisualizationService.ts';
import { InputTitle } from '../../../commons/components/InputTitle';
import { useUpdateVisualization } from '../../hooks/useVisualization.ts';
import { ElementTypeT } from '../../../../types/Presentation.ts';
import { VisualizationInteractionCard } from '../VisualizationInteractionCard';
import { AUTO_SAVE_DEBOUNCE_TIME, INPUT_TITLE_MAX_LENGHT } from '../../../../types/Constants.ts';
import debounce from 'lodash/debounce';
import { ElementConfigurationTopBar } from '../../../commons/components/ElementConfigurationTopBar';
import { Box } from '@chakra-ui/react';
import { SlideSettingsInput } from '../../../presentation/components/SlideSettingsInput';

interface Props {
	presentationId: string;
	visualization: VisualizationT;
}

export const RotatingWordsVisualization: React.FC<Props> = ({ presentationId, visualization }) => {
	const invalidatePresentation = useInvalidatePresentationQuery(presentationId);
	const updateVisualization = useUpdateVisualization(presentationId, visualization.id, (data: RotatingWordsConfigurationT) =>
		saveRotatingWordsVisualization(presentationId, visualization.id, data)
	);
	// const conf = visualization.configuration as RotatingWordsConfigurationT

	const { register, handleSubmit, watch } = useForm<RotatingWordsConfigurationT>({
		defaultValues: {
			title: visualization.title,
			description: visualization.description,
		},
	});

	const onSubmit: SubmitHandler<RotatingWordsConfigurationT> = async (data) => {
		updateVisualization.mutate(data);
	};

	const debouncedSubmit = debounce(handleSubmit(onSubmit), AUTO_SAVE_DEBOUNCE_TIME);

	useEffect(() => {
		const subscription = watch(() => {
			debouncedSubmit();
		});
		return () => {
			subscription.unsubscribe();
			invalidatePresentation();
		};
	}, [watch]);

	return (
		<>
			<ElementConfigurationTopBar presentationId={presentationId} element={visualization} elementType={ElementTypeT.VISUALIZATION} />
			<VisualizationInteractionCard interaction={visualization.interaction} />
			<form onSubmit={handleSubmit(onSubmit)}>
				<InputTitle
					label='Use {{word}} to add your rotating word to your sentence'
					title={watch('title')}
					{...register('title', {
						maxLength: INPUT_TITLE_MAX_LENGHT,
						required: true,
					})}
				/>
			</form>
			<Box mt={4}>
				<SlideSettingsInput presentationId={presentationId} slide={visualization.slide} />
			</Box>
		</>
	);
};
