import { Button, Collapse, FormControl, FormLabel, Input } from '@chakra-ui/react';
import { forwardRef, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { INPUT_DESCRIPTION_MAX_LENGHT } from '../../../../types/Constants.ts';
import { translate } from '../../../../translate';

interface Props {
	title: string;
}

export type Ref = HTMLInputElement;

export const InputSubtitle = forwardRef<Ref, Props>(({ title, ...props }, ref) => {
	const [show, setShow] = useState(!isEmpty(title));
	const handleToggle = () => setShow(!show);

	return (
		<div>
			{!show && (
				<Button variant='outline' onClick={handleToggle} size='sm' fontSize='xs' mt={4}>
					{translate('Add a description')}
				</Button>
			)}

			<Collapse in={show}>
				<FormControl mt={8}>
					<Input id='description' fontSize={18} fontWeight='bold' size='lg' data-peer required maxLength={INPUT_DESCRIPTION_MAX_LENGHT} ref={ref} {...props} />
					<FormLabel htmlFor='description' variant='floating' size='lg'>
						{translate('Description')}
					</FormLabel>
				</FormControl>
			</Collapse>
		</div>
	);
});

InputSubtitle.displayName = 'InputSubtitle';
