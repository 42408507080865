import { useQuery } from '@tanstack/react-query';
import { getVisualizationCompatibilityMap } from '../service/VisualizationService.ts';

const key = () => ['visualization', 'compatibility-map'];

export const useVisualizationCompatibilityMap = () => {
	return useQuery({
		queryKey: key(),
		queryFn: () => getVisualizationCompatibilityMap(),
		refetchOnWindowFocus: false,
		staleTime: Infinity,
	});
};
