/*
   Used in Icon picker in Author app. PictogramVisualization in the player reads from here
*/
export const IconPickerData = [
	{
		id: 'business',
		name: 'Business and Finance',
		emojis: [
			{
				id: 'chart-lines-up',
				name: 'Chart Lines Up',
				keywords: ['chart', 'lines', 'up', 'growth', 'increase', 'trend', 'data', 'statistics', 'business', 'analytics'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Business/chart-lines-up.svg',
					},
				],
			},
			{
				id: 'savings',
				name: 'Savings',
				keywords: ['savings', 'money', 'finance', 'bank', 'deposit', 'economy', 'investment', 'wealth', 'fund', 'saving account'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Business/savings.svg',
					},
				],
			},
			{
				id: 'dollar',
				name: 'Dollar',
				keywords: ['dollar', 'currency', 'money', 'finance', 'usd', 'cash', 'economy', 'banking', 'investment', 'transaction'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Business/dollar.svg',
					},
				],
			},
			{
				id: 'chart-heatmap',
				name: 'Chart Heatmap',
				keywords: ['chart', 'heatmap', 'data', 'visualization', 'matrix', 'statistics', 'analysis', 'business', 'information', 'graph'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Business/chart-heatmap.svg',
					},
				],
			},
			{
				id: 'briefcase',
				name: 'Briefcase',
				keywords: ['briefcase', 'business', 'professional', 'work', 'office', 'job', 'career', 'portfolio', 'case', 'corporate'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Business/briefcase.svg',
					},
				],
			},
			{
				id: 'wallet',
				name: 'Wallet',
				keywords: ['wallet', 'money', 'finance', 'personal finance', 'cash', 'payment', 'pocket', 'accessory', 'leather', 'savings'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Business/wallet.svg',
					},
				],
			},
			{
				id: 'chart-pie',
				name: 'Chart Pie',
				keywords: ['chart', 'pie', 'data', 'statistics', 'analysis', 'graph', 'sector', 'portion', 'business', 'report'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Business/chart-pie.svg',
					},
				],
			},
			{
				id: 'bank',
				name: 'Bank',
				keywords: ['bank', 'finance', 'money', 'savings', 'investment', 'economy', 'financial institution', 'deposit', 'transaction', 'banking'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Business/bank.svg',
					},
				],
			},
			{
				id: 'money',
				name: 'Money',
				keywords: ['money', 'currency', 'cash', 'finance', 'economy', 'wealth', 'payment', 'dollar', 'coin', 'banknote'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Business/money.svg',
					},
				],
			},
			{
				id: 'handshake',
				name: 'Handshake',
				keywords: ['handshake', 'agreement', 'deal', 'partnership', 'business', 'cooperation', 'meeting', 'contract', 'trust', 'collaboration'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Business/handshake.svg',
					},
				],
			},
			{
				id: 'calendar',
				name: 'Calendar',
				keywords: ['calendar', 'date', 'schedule', 'appointment', 'event', 'planner', 'organizer', 'day', 'month', 'year'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Business/calendar.svg',
					},
				],
			},
			{
				id: 'chart-area',
				name: 'Chart Area',
				keywords: ['chart', 'area', 'graph', 'data', 'statistics', 'analysis', 'trend', 'business', 'report', 'visualization'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Business/chart-area.svg',
					},
				],
			},
		],
	},
	{
		id: 'comunication',
		name: 'Comunication',
		emojis: [
			{
				id: 'conversation-alt',
				name: 'Conversation Alt',
				keywords: ['speech bubble', 'dialogue', 'conversation', 'communication', 'chat', 'discussion', 'talk', 'dialogue', 'speech', 'interaction', 'message', 'alternate'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Communication/conversation-alt.svg',
					},
				],
			},
			{
				id: 'web-browser',
				name: 'Web Browser',
				keywords: ['web', 'browser', 'internet', 'online', 'search', 'website', 'surfing', 'technology', 'window', 'digital'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Communication/web-browser.svg',
					},
				],
			},
			{
				id: 'microphone',
				name: 'Microphone',
				keywords: ['microphone', 'audio', 'sound', 'recording', 'voice', 'broadcast', 'communication', 'singing', 'speech', 'technology'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Communication/microphone.svg',
					},
				],
			},
			{
				id: 'newspaper',
				name: 'Newspaper',
				keywords: ['newspaper', 'news', 'media', 'journalism', 'paper', 'article', 'press', 'publication', 'information', 'reading'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Communication/newspaper.svg',
					},
				],
			},
			{
				id: 'document',
				name: 'Document',
				keywords: ['document', 'file', 'paper', 'text', 'record', 'report', 'writing', 'information', 'data', 'sheet'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Communication/document.svg',
					},
				],
			},
			{
				id: 'email',
				name: 'Email',
				keywords: ['email', 'mail', 'communication', 'message', 'inbox', 'correspondence', 'electronic', 'send', 'receive', 'letter'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Communication/email.svg',
					},
				],
			},
			{
				id: 'speaker',
				name: 'Speaker',
				keywords: ['speaker', 'audio', 'sound', 'music', 'loud', 'amplifier', 'volume', 'broadcast', 'stereo', 'device'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Communication/speaker.svg',
					},
				],
			},
			{
				id: 'wi-fi',
				name: 'Wi-Fi',
				keywords: ['wi-fi', 'wireless', 'internet', 'network', 'connection', 'signal', 'technology', 'router', 'access', 'online'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Communication/wi-fi.svg',
					},
				],
			},
			{
				id: 'hashtag',
				name: 'Hashtag',
				keywords: ['hashtag', 'social media', 'trending', 'tag', 'topic', 'keyword', 'pound', 'label', 'internet', 'search'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Communication/hashtag.svg',
					},
				],
			},
			{
				id: 'phone',
				name: 'Phone',
				keywords: ['phone', 'telephone', 'communication', 'call', 'voice', 'dial', 'contact', 'conversation', 'receiver', 'technology'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Communication/phone.svg',
					},
				],
			},
			{
				id: 'conversation',
				name: 'Conversation',
				keywords: ['conversation', 'speech bubble', 'dialogue', 'chat', 'talk', 'discussion', 'dialogue', 'communication', 'speech', 'exchange', 'interaction', 'message'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Communication/conversation.svg',
					},
				],
			},
			{
				id: 'video-camera',
				name: 'Video Camera',
				keywords: ['video camera', 'recording', 'film', 'movie', 'videography', 'technology', 'media', 'broadcast', 'cinema', 'shoot'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Communication/video-camera.svg',
					},
				],
			},
			{
				id: 'laptop',
				name: 'Laptop',
				keywords: ['laptop', 'computer', 'technology', 'portable', 'device', 'notebook', 'electronics', 'internet', 'work', 'personal computer'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Communication/laptop.svg',
					},
				],
			},
		],
	},
	{
		id: 'education',
		name: 'Education',
		emojis: [
			{
				id: 'student-b',
				name: 'Student B',
				keywords: ['student', 'education', 'learner', 'school', 'college', 'university', 'study', 'youth', 'academic', 'scholar'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Education/student-b.svg',
					},
				],
			},
			{
				id: 'student-a',
				name: 'Student A',
				keywords: ['student', 'education', 'learning', 'school', 'academia', 'pupil', 'study', 'class', 'scholar', 'youth'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Education/student-a.svg',
					},
				],
			},
			{
				id: 'library',
				name: 'Library',
				keywords: ['library', 'books', 'education', 'reading', 'literature', 'knowledge', 'study', 'research', 'academic', 'bookshelves'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Education/library.svg',
					},
				],
			},
			{
				id: 'brain',
				name: 'Brain',
				keywords: ['brain', 'mind', 'intelligence', 'thinking', 'neurology', 'psychology', 'knowledge', 'cognition', 'mental', 'smart'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Education/brain.svg',
					},
				],
			},
			{
				id: 'certificate',
				name: 'Certificate',
				keywords: ['certificate', 'award', 'education', 'achievement', 'diploma', 'recognition', 'qualification', 'accreditation', 'completion', 'credential'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Education/certificate.svg',
					},
				],
			},
			{
				id: 'school',
				name: 'School',
				keywords: ['school', 'education', 'learning', 'institution', 'students', 'teaching', 'academy', 'classroom', 'study', 'building'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Education/school.svg',
					},
				],
			},
			{
				id: 'students',
				name: 'Students',
				keywords: ['students', 'group', 'education', 'school', 'college', 'university', 'class', 'youth', 'learning', 'scholars'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Education/students.svg',
					},
				],
			},
			{
				id: 'book',
				name: 'Book',
				keywords: ['book', 'reading', 'literature', 'education', 'knowledge', 'library', 'textbook', 'study', 'publish', 'pages'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Education/book.svg',
					},
				],
			},
			{
				id: 'teacher',
				name: 'Teacher',
				keywords: ['teacher', 'education', 'instructor', 'school', 'classroom', 'mentor', 'tutor', 'educator', 'professor', 'guide'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Education/teacher.svg',
					},
				],
			},
			{
				id: 'ruler',
				name: 'Ruler',
				keywords: ['ruler', 'measure', 'tool', 'length', 'school', 'drawing', 'straight edge', 'geometry', 'stationery', 'measurement'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Education/ruler.svg',
					},
				],
			},
			{
				id: 'blackboard',
				name: 'Blackboard',
				keywords: ['blackboard', 'chalkboard', 'education', 'teaching', 'school', 'classroom', 'writing', 'learning', 'board', 'lecture'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Education/blackboard.svg',
					},
				],
			},
			{
				id: 'pencil',
				name: 'Pencil',
				keywords: ['pencil', 'writing', 'drawing', 'stationery', 'education', 'sketch', 'graphite', 'school', 'art', 'tool'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Education/pencil.svg',
					},
				],
			},
			{
				id: 'graduation',
				name: 'Graduation',
				keywords: ['graduation', 'education', 'ceremony', 'cap', 'gown', 'degree', 'university', 'college', 'achievement', 'diploma'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Education/graduation.svg',
					},
				],
			},
			{
				id: 'globe',
				name: 'Globe',
				keywords: ['globe', 'world', 'earth', 'geography', 'planet', 'map', 'global', 'international', 'sphere', 'travel'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Education/globe.svg',
					},
				],
			},
			{
				id: 'magnifying-glass',
				name: 'Magnifying Glass',
				keywords: ['magnifying glass', 'search', 'zoom', 'inspect', 'lens', 'focus', 'investigate', 'detail', 'enlarge', 'discover'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Education/magnifying-glass.svg',
					},
				],
			},
			{
				id: 'atom',
				name: 'Atom',
				keywords: ['atom', 'science', 'molecule', 'physics', 'chemistry', 'energy', 'nuclear', 'electron', 'proton', 'neutron'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Education/atom.svg',
					},
				],
			},
		],
	},
	{
		id: 'environment',
		name: 'Nature and environment',
		emojis: [
			{
				id: 'lightning',
				name: 'Lightning',
				keywords: ['lightning', 'electricity', 'storm', 'weather', 'energy', 'bolt', 'power', 'nature', 'thunder', 'charge'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Nature/lightning.svg',
					},
				],
			},
			{
				id: 'earth',
				name: 'Earth',
				keywords: ['earth', 'planet', 'world', 'globe', 'nature', 'environment', 'geography', 'global', 'space', 'terrestrial'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Nature/earth.svg',
					},
				],
			},
			{
				id: 'recycling',
				name: 'Recycling',
				keywords: ['recycling', 'environment', 'sustainability', 'reuse', 'waste', 'eco-friendly', 'green', 'conservation', 'recycle bin', 'nature'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Nature/recycling.svg',
					},
				],
			},
			{
				id: 'win',
				name: 'Win',
				keywords: ['win', 'victory', 'success', 'achievement', 'triumph', 'celebration', 'winner', 'champion', 'accomplishment', 'goal'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Nature/win.svg',
					},
				],
			},
			{
				id: 'clouds',
				name: 'Clouds',
				keywords: ['clouds', 'sky', 'weather', 'nature', 'fluffy', 'atmosphere', 'cloudy', 'air', 'climate', 'overcast'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Nature/clouds.svg',
					},
				],
			},
			{
				id: 'water-drop',
				name: 'Water Drop',
				keywords: ['water drop', 'liquid', 'drip', 'rain', 'nature', 'hydration', 'wet', 'moisture', 'droplet', 'aqua'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Nature/water-drop.svg',
					},
				],
			},
			{
				id: 'fire',
				name: 'Fire',
				keywords: ['fire', 'flame', 'heat', 'burn', 'hot', 'campfire', 'wildfire', 'energy', 'danger', 'light'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Nature/fire.svg',
					},
				],
			},
			{
				id: 'river',
				name: 'River',
				keywords: ['river', 'water', 'stream', 'nature', 'flow', 'freshwater', 'landscape', 'current', 'aquatic', 'bank'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Nature/river.svg',
					},
				],
			},
		],
	},
	{
		id: 'social',
		name: 'Social & People',
		emojis: [
			{
				id: 'sports',
				name: 'Sports',
				keywords: ['sports', 'athletics', 'competition', 'team', 'fitness', 'exercise', 'game', 'physical', 'play', 'active'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Social/sports.svg',
					},
				],
			},
			{
				id: 'family',
				name: 'Family',
				keywords: ['family', 'relatives', 'home', 'love', 'kinship', 'children', 'parents', 'bonding', 'support', 'togetherness'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Social/family.svg',
					},
				],
			},
			{
				id: 'party',
				name: 'Party',
				keywords: ['party', 'celebration', 'fun', 'music', 'dance', 'event', 'gathering', 'festivity', 'entertainment', 'social'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Social/party.svg',
					},
				],
			},
			{
				id: 'kids',
				name: 'Kids',
				keywords: ['kids', 'children', 'youth', 'play', 'learning', 'fun', 'school', 'innocence', 'growth', 'family'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Social/kids.svg',
					},
				],
			},
			{
				id: 'partners',
				name: 'Partners',
				keywords: ['partners', 'relationship', 'couple', 'togetherness', 'love', 'companionship', 'bond', 'support', 'teamwork', 'unity'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Social/partners.svg',
					},
				],
			},
			{
				id: 'crowd',
				name: 'Crowd',
				keywords: ['crowd', 'people', 'group', 'gathering', 'community', 'mass', 'public', 'assembly', 'multitude', 'society'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Social/crowd.svg',
					},
				],
			},
			{
				id: 'meeting',
				name: 'Meeting',
				keywords: ['meeting', 'conference', 'discussion', 'business', 'team', 'collaboration', 'planning', 'strategy', 'session', 'talk'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Social/meeting.svg',
					},
				],
			},
			{
				id: 'pregnant',
				name: 'Pregnant',
				keywords: ['pregnant', 'maternity', 'motherhood', 'baby', 'expecting', 'pregnancy', 'parenthood', 'birth', 'family', 'life'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Social/pregnant.svg',
					},
				],
			},
			{
				id: 'baby',
				name: 'Baby',
				keywords: ['baby', 'infant', 'newborn', 'child', 'toddler', 'cute', 'family', 'care', 'love', 'growth'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Social/baby.svg',
					},
				],
			},
			{
				id: 'pet',
				name: 'Pet',
				keywords: ['pet', 'animal', 'companion', 'dog', 'cat', 'care', 'love', 'family', 'friendship', 'loyalty'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Social/pet.svg',
					},
				],
			},
			{
				id: 'woman',
				name: 'Woman',
				keywords: ['woman', 'female', 'lady', 'girl', 'feminine', 'strength', 'beauty', 'empowerment', 'diversity', 'equality'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Social/woman.svg',
					},
				],
			},
			{
				id: 'conversation',
				name: 'Conversation',
				keywords: ['conversation', 'talk', 'discussion', 'chat', 'communication', 'dialogue', 'speech', 'exchange', 'interaction', 'debate'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Social/conversation.svg',
					},
				],
			},
			{
				id: 'worker',
				name: 'Worker',
				keywords: ['worker', 'labor', 'professional', 'employee', 'job', 'career', 'occupation', 'industry', 'workforce', 'business'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Social/worker.svg',
					},
				],
			},
			{
				id: 'man',
				name: 'Man',
				keywords: ['man', 'male', 'guy', 'gentleman', 'masculine', 'strength', 'bravery', 'diversity', 'equality', 'leadership'],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Social/man.svg',
					},
				],
			},
			{
				id: 'workplace',
				name: 'Workplace',
				keywords: [
					'office',
					'desk',
					'computer',
					'teamwork',
					'meeting',
					'business',
					'corporate',
					'workplace',
					'employee',
					'manager',
					'collaboration',
					'workspace',
					'briefcase',
					'presentation',
					'conference',
					'networking',
					'professional',
					'workstation',
					'technology',
					'innovation',
				],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Social/workplace.svg',
					},
				],
			},
			{
				id: 'office',
				name: 'office',
				keywords: [
					'office',
					'desk',
					'telephone',
					'business call',
					'professional',
					'office worker',
					'communication',
					'calling',
					'workplace',
					'employee at desk',
					'phone conversation',
					'business communication',
					'office environment',
					'desk phone',
					'working professional',
					'office setting',
					'corporate call',
					'businessperson',
					'office job',
				],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Social/office.svg',
					},
				],
			},
		],
	},
	{
		id: 'technology',
		name: 'Technology and Gadgets',
		emojis: [
			{
				id: 'camera',
				name: 'camera',
				keywords: [],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Technology/camera.svg',
					},
				],
			},
			{
				id: 'flash-drive',
				name: 'flash-drive',
				keywords: [
					'pen drive',
					'USB',
					'flash drive',
					'data storage',
					'thumb drive',
					'portable memory',
					'USB stick',
					'external storage',
					'memory stick',
					'digital storage',
					'file transfer',
					'backup drive',
					'USB flash drive',
					'data stick',
					'computer accessory',
				],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Technology/flash-drive.svg',
					},
				],
			},
			{
				id: 'game-controller',
				name: 'game-controller',
				keywords: [
					'gaming controller',
					'gamepad',
					'joystick',
					'video game',
					'console controller',
					'gaming device',
					'wireless controller',
					'game control',
					'handheld controller',
					'interactive gaming',
					'gamer gear',
					'playstation controller',
					'xbox controller',
					'PC gaming',
					'virtual gaming',
				],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Technology/game-controller.svg',
					},
				],
			},
			{
				id: 'id',
				name: 'id',
				keywords: [],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Technology/game-controller.svg',
					},
				],
			},
			{
				id: 'id',
				name: 'id',
				keywords: [],
				skins: [
					{
						src: 'https://questiory-static-media.onrender.com/icons/Technology/game-controller.svg',
					},
				],
			},
		],
	},
];
