import ApiClient from '../../../apiClient';
import { BasicPresentationT } from '../../../types/Presentation.ts';
import { InteractionT, InteractionTypeT } from '../../../types/Interaction.ts';
import { Entry } from '../model/entry.ts';

export interface EntryListResponse {
	entries: Entry[];
	interaction: BasicInteraction;
}

interface BasicInteraction {
	title: string;
	type: InteractionTypeT;
}

export interface SearchEntriesInput {
	query: string;
	pageNumber: number;
}

export const searchEntries = async (presentationId: BasicPresentationT['id'], interactionId: InteractionT['id'], input: SearchEntriesInput): Promise<EntryListResponse> => {
	return (
		await ApiClient.getInstance().get<EntryListResponse>(`/author-app/presentations/${presentationId}/interactions/${interactionId}/entries`, {
			params: {
				q: input.query,
				pageNumber: input.pageNumber,
			},
		})
	).data;
};

// ------

export interface ModerateEntryInput {
	entryId: number;
	hidden: boolean;
}

export const moderateEntry = async (presentationId: BasicPresentationT['id'], interactionId: InteractionT['id'], input: ModerateEntryInput): Promise<void> => {
	await ApiClient.getInstance().post(`/author-app/presentations/${presentationId}/interactions/${interactionId}/entries`, input);
};

// ------
export const deleteEntriesByInteraction = async (presentationId: BasicPresentationT['id'], interactionId: InteractionT['id']): Promise<void> => {
	await ApiClient.getInstance().delete(`/author-app/presentations/${presentationId}/interactions/${interactionId}/entries`);
};
