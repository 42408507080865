import { Flex } from '@chakra-ui/react';
import React from 'react';
import { ElementType } from '../../../presentation/components/ElementType';
import { ElementTypeT } from '../../../../types/Presentation.ts';
import { ElementMoreMenu } from '../ElementMoreMenu';
import { InteractionT } from '../../../../types/Interaction.ts';
import { VisualizationT } from '../../../../types/Visualization.ts';
import { StaticContentT } from '../../../../types/StaticContent.ts';

interface Props {
	presentationId: string;
	elementType: ElementTypeT;
	element: InteractionT | VisualizationT | StaticContentT;
}

export const ElementConfigurationTopBar: React.FC<Props> = ({ presentationId, elementType, element }) => {
	return (
		<Flex flexDirection='row' justifyContent='space-between'>
			<Flex justifyContent='right'>
				<ElementType elementType={elementType} elementSubType={element.type} />
			</Flex>
			<Flex>
				<ElementMoreMenu element={element} elementType={elementType} presentationId={presentationId} />
			</Flex>
		</Flex>
	);
};
