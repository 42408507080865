import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const modalSize = defineStyle({
	maxW: '83rem',
});

const sizes = {
	modalSize: definePartsStyle({ dialog: modalSize }),
};

export const modalTheme = defineMultiStyleConfig({
	sizes,
});
