import { Divider, Flex, Text } from '@chakra-ui/react';
import truncate from 'lodash/truncate';
import React, { useEffect } from 'react';
import { BasicSlideT } from '../../../../../../types/Presentation.ts';
import { mapElementTypeToWords } from '../../../../../../util/InteractionUtils.ts';
import { SlideMiniature } from '../SlideMiniature';

interface Props {
	slide: BasicSlideT;
	isSelected: boolean;
	onSelectSlide: (slide: BasicSlideT) => void;
}

export const SlideBox: React.FC<Props> = ({ slide, isSelected, onSelectSlide }) => {
	const handleOnSelectedSlide = () => {
		onSelectSlide(slide);
	};

	const slideId = `slide-${String(slide.id)}`;

	useEffect(() => {
		// document.getElementById(slideId)?.scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'nearest' });
	}, [slideId, isSelected]);

	return (
		<SlideMiniature
			id={slideId}
			fontWeight='bold'
			onClick={handleOnSelectedSlide}
			// borderRight={isSelected ? "8px solid" : ""}
			// borderRightColor={isSelected ? "brand" : ""}
			backgroundColor={isSelected ? 'white' : 'colorScheme.100'}
			textAlign='center'
			flexDirection='column'
			justifyContent='space-around'
		>
			{isSelected ? (
				<Flex flexDirection='column'>
					<Flex
						h='4.2rem'
						overflowY='auto'
						style={{
							scrollbarColor: 'transparent transparent',
						}}
					>
						<Text textAlign='center' m='auto' textDecoration='none'>
							{slide.title}
						</Text>
					</Flex>
					<Divider variant='dashed' my={2} />
					<Text fontSize={12} textTransform='uppercase'>
						{mapElementTypeToWords(slide.element.type)}
					</Text>
				</Flex>
			) : (
				<Text textAlign='center' textDecoration='none'>
					{truncate(slide.title, { length: 40 })}
				</Text>
			)}
		</SlideMiniature>
	);
};
