import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getPresentationById } from '../service/PresentationService.ts';
import { BasicPresentationT } from '../../../types/Presentation.ts';

const key = (presentationId: string) => ['presentation', 'basic-presentation', presentationId];

export const usePresentation = (presentationId: string, placeholderData?: BasicPresentationT) => {
	return useQuery({
		queryKey: key(presentationId),
		queryFn: () => getPresentationById(presentationId),
		placeholderData: placeholderData,
		staleTime: Infinity,
		refetchOnWindowFocus: false,
	});
};

export const useInvalidatePresentationQuery = (presentationId: string) => {
	const queryClient = useQueryClient();
	return () => queryClient.invalidateQueries({ queryKey: key(presentationId) });
};
