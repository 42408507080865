import React, { useEffect } from 'react';
import { Alert, AlertIcon, AlertTitle, Flex, FormControl, FormLabel, Switch } from '@chakra-ui/react';
import { Loading } from '../../../../../commons/components/Loading';
import { useInvalidatePresentationQuery, usePresentation } from '../../../../hooks/usePresentation.ts';
import { updatePresentationSettings, UpdatePresentationSettingsInput } from '../../../../service/PresentationService.ts';
import { SubmitHandler, useForm } from 'react-hook-form';
import debounce from 'lodash/debounce';
import { AUTO_SAVE_DEBOUNCE_TIME } from '../../../../../../types/Constants.ts';
import { translate } from '../../../../../../translate';

interface Props {
	presentationId: string;
}

export const BasicPresentationSettings: React.FC<Props> = ({ presentationId }) => {
	const { isPending, data, isError } = usePresentation(presentationId);
	const invalidatePresentation = useInvalidatePresentationQuery(presentationId);
	const { register, watch, handleSubmit } = useForm<UpdatePresentationSettingsInput>({
		defaultValues: {
			hideProgressBar: data?.hideProgressBar,
			hideControls: data?.hideControls,
		},
	});

	const onSubmit: SubmitHandler<UpdatePresentationSettingsInput> = async (data) => {
		await updatePresentationSettings(presentationId, data);
	};

	const debouncedSubmit = debounce(handleSubmit(onSubmit), AUTO_SAVE_DEBOUNCE_TIME);

	useEffect(() => {
		const subscription = watch(() => {
			debouncedSubmit();
		});
		return () => {
			subscription.unsubscribe();
			invalidatePresentation();
		};
	}, [watch, handleSubmit]);

	if (isPending) {
		return <Loading />;
	}

	if (isError) {
		return (
			<Alert status='error'>
				<AlertIcon />
				<AlertTitle>{translate('Error fetching settings')}</AlertTitle>
			</Alert>
		);
	}

	return (
		<Flex flexDirection='column' gap={2} as='form' onSubmit={handleSubmit(onSubmit)}>
			<FormControl display='flex' alignItems='center'>
				<Switch size='lg' id={`HideProgressBar/${presentationId}`} {...register('hideProgressBar')} />
				<FormLabel htmlFor={`HideProgressBar/${presentationId}`} fontSize='lg' mx={2}>
					{translate('Hide Progress Bar')}
				</FormLabel>
			</FormControl>
			<FormControl display='flex' alignItems='center'>
				<Switch size='lg' id={`HideControls/${presentationId}`} {...register('hideControls')} />
				<FormLabel htmlFor={`HideControls/${presentationId}`} fontSize='lg' mx={2}>
					{translate('Hide Navigation Arrows')}
				</FormLabel>
			</FormControl>
		</Flex>
	);
};
