import { ContentConfigurationCoverT, StaticContentT } from '../../../../types/StaticContent.ts';
import { useUpdateStaticContent } from '../../hooks/useStaticContent.ts';
import { saveContentCover } from '../../service/StaticContentService.ts';
import { BasicContentTypeForm } from '../BasicContentTypeForm';
import { BasicContentTypeFormValues } from '../BasicContentTypeForm/BasicContentTypeForm.tsx';

interface Props {
	presentationId: string;
	content: StaticContentT;
}

export function StaticContentCover({ presentationId, content }: Props) {
	const updateStaticContent = useUpdateStaticContent(presentationId, content.id, (data: ContentConfigurationCoverT) => {
		saveContentCover(presentationId, content.id, data);
	});

	const onSubmit = async (data: BasicContentTypeFormValues) => {
		updateStaticContent.mutate({
			title: data.title,
			description: data.description,
		});
	};

	return (
		<>
			<BasicContentTypeForm presentationId={presentationId} content={content} onSubmit={onSubmit} />
		</>
	);
}
