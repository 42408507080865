import { BasicPresentationT, BasicSlideT } from '../../../types/Presentation.ts';

export const openPresentationInClientApp = (presentationId?: BasicPresentationT['id']) => {
	window.open(getClientAppPresentationUrl(presentationId), '_blank');
};

export const getClientAppPresentationUrl = (presentationId?: BasicPresentationT['id']): string => {
	const clientBaseUrl = `${import.meta.env.VITE_FRONTEND_APP_URL}`;

	return `${clientBaseUrl}/presentation/${presentationId}`;
};

export const getClienAppSlideUrl = (presentationId?: BasicPresentationT['id'], slideId?: BasicSlideT['id']): string => {
	const presentationUrl = getClientAppPresentationUrl(presentationId);
	// This is because the frontend uses the index of the array presentation.slides and not slide.index, this only used for sorting in the api
	return `${presentationUrl}/slides/${slideId}`;
};

export const getClienAppSlideUrlPreview = (presentationId?: BasicPresentationT['id'], slideId?: BasicSlideT['id']): string => {
	return `${getClienAppSlideUrl(presentationId, slideId)}/preview`;
};
