import { BasicPresentationT } from '../../../types/Presentation.ts';
import { InteractionT } from '../../../types/Interaction.ts';
import { useMutation } from '@tanstack/react-query';
import { deleteEntriesByInteraction } from '../service/EntryService.ts';

export const useDeleteAllEntries = (presentationId: BasicPresentationT['id'], interactionId: InteractionT['id']) => {
	return useMutation({
		mutationFn: () => {
			return deleteEntriesByInteraction(presentationId, interactionId);
		},
	});
};
