import React, { useEffect } from 'react';
import { Box, Flex, FormControl, FormLabel, Icon, Input, Switch, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { VisualizationT, WordCloudConfigurationT } from '../../../../types/Visualization.ts';
import { useInvalidatePresentationQuery } from '../../../presentation/hooks/usePresentation.ts';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { saveBarChartVisualization } from '../../service/VisualizationService.ts';
import { InputTitle } from '../../../commons/components/InputTitle';
import { InputSubtitle } from '../../../commons/components/InputSubtitle';
import { useUpdateVisualization } from '../../hooks/useVisualization.ts';
import { ElementTypeT } from '../../../../types/Presentation.ts';
import { VisualizationInteractionCard } from '../VisualizationInteractionCard';
import { AUTO_SAVE_DEBOUNCE_TIME, INPUT_DESCRIPTION_MAX_LENGHT, INPUT_TITLE_MAX_LENGHT, MAX_SELECTED_ANSWER_REFERENCE_LENGTH } from '../../../../types/Constants.ts';
import debounce from 'lodash/debounce';
import { ElementConfigurationTopBar } from '../../../commons/components/ElementConfigurationTopBar';
import { PiTextAlignJustifyFill } from 'react-icons/pi';
import { SlideSettingsInput } from '../../../presentation/components/SlideSettingsInput';
import { TagSelector } from '../../../commons/components/TagSelector';
import { IoSettingsSharp } from 'react-icons/io5';
import { translate } from '../../../../translate';

interface Props {
	presentationId: string;
	visualization: VisualizationT;
}

export const BarChartVisualization: React.FC<Props> = ({ presentationId, visualization }) => {
	const invalidatePresentation = useInvalidatePresentationQuery(presentationId);
	const updateVisualization = useUpdateVisualization(presentationId, visualization.id, (data: WordCloudConfigurationT) =>
		saveBarChartVisualization(presentationId, visualization.id, data)
	);
	const conf = visualization.configuration as WordCloudConfigurationT;

	const { register, handleSubmit, watch, control, setValue } = useForm<WordCloudConfigurationT>({
		defaultValues: {
			title: visualization.title,
			description: visualization.description,
			reference: conf.reference,
			selectedAnswersActive: conf.selectedAnswersActive,
			selectedAnswers: conf.selectedAnswers,
		},
	});

	const onSubmit: SubmitHandler<WordCloudConfigurationT> = async (data) => {
		updateVisualization.mutate(data);
	};

	const debouncedSubmit = debounce(handleSubmit(onSubmit), AUTO_SAVE_DEBOUNCE_TIME);

	useEffect(() => {
		const subscription = watch(() => {
			debouncedSubmit();
		});
		return () => {
			subscription.unsubscribe();
			invalidatePresentation();
		};
	}, [watch]);

	const handleOnChangeSelectedAnswerActive = (e: React.ChangeEvent<HTMLInputElement>) => {
		const selectedAnswersActive = e.target.checked;
		if (!selectedAnswersActive) {
			setValue('selectedAnswers', []);
			setValue('reference', '');
		}
	};

	return (
		<>
			<ElementConfigurationTopBar presentationId={presentationId} element={visualization} elementType={ElementTypeT.VISUALIZATION} />
			<form onSubmit={handleSubmit(onSubmit)}>
				<Tabs variant='line' my={4}>
					<TabList>
						<Tab fontSize={20}>
							<Icon as={PiTextAlignJustifyFill} pr={2} />
							{translate('Content')}
						</Tab>
						<Tab fontSize={20}>
							<Icon as={IoSettingsSharp} pr={2} />
							{translate('Settings')}
						</Tab>
					</TabList>
					<TabPanels>
						<TabPanel>
							<VisualizationInteractionCard interaction={visualization.interaction} />

							<Flex flexDirection='column'>
								<InputTitle
									title={watch('title')}
									{...register('title', {
										maxLength: INPUT_TITLE_MAX_LENGHT,
										required: true,
									})}
								/>
							</Flex>
							<Flex flexDirection='column'>
								<InputSubtitle
									title={watch('description')}
									{...register('description', {
										maxLength: INPUT_DESCRIPTION_MAX_LENGHT,
									})}
								/>
							</Flex>
							<Flex flexDirection='column'>
								<Flex>
									<Switch
										id='selectedAnswersActive'
										size='lg'
										{...register('selectedAnswersActive', {
											onChange: handleOnChangeSelectedAnswerActive,
										})}
									/>
									<FormLabel htmlFor='selectedAnswersActive' fontSize='lg' mx={2}>
										{translate('Has selected answers')}
									</FormLabel>
								</Flex>

								{watch('selectedAnswersActive') ? (
									<Flex flexDirection='column' my={8}>
										<FormControl>
											<Input
												type='text'
												size='lg'
												placeholder=' '
												id='reference'
												data-peer
												maxLength={MAX_SELECTED_ANSWER_REFERENCE_LENGTH}
												{...register('reference', { maxLength: MAX_SELECTED_ANSWER_REFERENCE_LENGTH })}
											/>
											<FormLabel htmlFor='reference' variant='floating' size='lg'>
												{translate('Selected answers reference')}
											</FormLabel>
										</FormControl>
										<Controller
											name='selectedAnswers'
											control={control}
											rules={{ required: false, minLength: 0, maxLength: 5 }}
											render={({ field }) => (
												<TagSelector
													name={field.name}
													onBlur={field.onBlur}
													onChange={field.onChange}
													value={field.value}
													minEntries={0}
													maxEntries={5}
													placeholder={translate('Add accepted entry')}
												/>
											)}
										/>
									</Flex>
								) : null}
							</Flex>
						</TabPanel>
						<TabPanel>
							<Box mt={4}>
								<SlideSettingsInput presentationId={presentationId} slide={visualization.slide} />
							</Box>
						</TabPanel>
					</TabPanels>
				</Tabs>
			</form>
		</>
	);
};
