import React from 'react';
import { Button, ButtonGroup } from '@chakra-ui/react';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { translate } from '../../../../translate';

interface Props {
	canGoForward: boolean;
	currentPage: number;
	onPageChange: (pageNumber: number) => void;
}

export const EntryListPagination: React.FC<Props> = ({ canGoForward, currentPage, onPageChange }) => {
	const handleOnBack = () => {
		const newValue = currentPage > 1 ? currentPage - 1 : 1;
		onPageChange(newValue);
	};

	const handleOnForward = () => {
		const newValue = currentPage + 1;
		onPageChange(newValue);
	};

	return (
		<ButtonGroup my={1} gap={2} variant='solid'>
			<Button aria-label='prev' onClick={handleOnBack} leftIcon={<MdChevronLeft />} isDisabled={currentPage <= 1}>
				{translate('Prev')}
			</Button>
			<Button aria-label='next' onClick={handleOnForward} rightIcon={<MdChevronRight />} isDisabled={!canGoForward}>
				{translate('Next')}
			</Button>
		</ButtonGroup>
	);
};
