import React from 'react';
import { Button, Flex, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, useDisclosure } from '@chakra-ui/react';
import { translate } from '../../../../translate';
import { ThemeListPage } from '../../pages/ThemeListPage';
import { HiPaintBrush } from 'react-icons/hi2';

interface Props {
	presentationId: string;
}

export const ThemePicker: React.FC<Props> = ({ presentationId }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	return (
		<>
			<Button rightIcon={<HiPaintBrush />} variant='ghost' size='lg' onClick={onOpen} px={5}>
				{translate('Themes')}
			</Button>
			<Modal size='full' isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalCloseButton />
					<ModalBody mx={24}>
						<Flex flexDirection='column' gap={10} justifyContent='center' alignItems='center'>
							<Heading size='sm' textAlign='center'>
								{translate('Themes')}
							</Heading>
							<ThemeListPage presentationId={presentationId} />
						</Flex>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
};
