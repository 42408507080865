import { useParams } from 'react-router-dom';

export const usePresentationId = (): string => {
	const { presentationId } = useParams();
	return presentationId!;
};

export const useSlideId = (): string | undefined => {
	const { slideId } = useParams();
	return slideId ? slideId : undefined;
};
