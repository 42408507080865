import React, { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useExternalScript } from '../../hooks/useExternalScript.ts';

interface Props {}

export const GoogleAnalytics: React.FC<Props> = () => {
	const handleOnLoad = useCallback(() => {
		// Check if gtag.js script is already loaded
		//@ts-expect-error: Ignore
		if (!window.gtag) {
			// Define the gtag function
			//@ts-expect-error: Ignore
			window.dataLayer = window.dataLayer || [];
			//@ts-expect-error: Ignore
			window.gtag = function () {
				//@ts-expect-error: Ignore
				//eslint-disable-next-line
				dataLayer.push(arguments);
			};
			//@ts-expect-error: Ignore
			gtag('js', new Date());
			//@ts-expect-error: Ignore
			gtag('config', import.meta.env.VITE_GA_TRACKING_ID, {
				page_path: window.location.pathname,
				cookie_flags: 'SameSite=None;Secure',
			});
		}
	}, []);

	useExternalScript({
		id: 'google-analytics',
		src: `https://www.googletagmanager.com/gtag/js?id=${import.meta.env.VITE_GA_TRACKING_ID}`,
		onLoad: handleOnLoad,
	});

	usePageViews();
	return null;
};

const usePageViews = () => {
	const location = useLocation();

	useEffect(() => {
		const currentPath = location.pathname + location.search;
		// Ensure gtag and your tracking ID are defined
		//@ts-expect-error: ignore
		if (typeof window !== 'undefined' && window.gtag && import.meta.env.VITE_GA_TRACKING_ID) {
			//@ts-expect-error: Ignore
			window.gtag('event', 'page_view', {
				page_location: window.location.pathname, // Optional: Provide the full URL of the page
				page_path: currentPath, // Optional: Provide the path portion of the URL
			});
		}
	}, [location.pathname, location.search]);
};
