import React, { useState } from 'react';
import { ExistingDataVisualization } from './_/ExistingDataVisualization';
import { Flex } from '@chakra-ui/react';
import { IconBox } from '../../../commons/components/IconBox';
import { NewDataVisualization } from './_/NewDataVisualization';
import { NewElementHeader } from '../../../commons/components/NewElementHeader';
import { translate } from '../../../../translate';

interface Props {
	onGoBack: () => void;
}

enum Operation {
	NO_OPERATION,
	EXISTING_DATA_VISUALIZATION,
	NEW_DATA_VISUALIZATION,
}

export const VisualizationSelector: React.FC<Props> = ({ onGoBack }) => {
	const [operation, setOperation] = useState(Operation.NO_OPERATION);

	const handleOnGoBack = () => {
		setOperation(Operation.NO_OPERATION);
	};

	return (
		<>
			{operation === Operation.NO_OPERATION ? (
				<Flex flexDirection='column' alignItems='center'>
					<NewElementHeader title={translate('How would you like to start your visualization?')} onGoBack={onGoBack} />
					<Flex flexDirection={['column', null, 'row']} gap={8} alignItems='center'>
						<IconBox
							iconImageSrc='/assets/brand/icons/VIEW_EXISTING.svg'
							label={translate('Existing')}
							description={translate('View and use data from an existing Interaction')}
							onClick={() => setOperation(Operation.EXISTING_DATA_VISUALIZATION)}
						/>
						<IconBox
							iconImageSrc='/assets/brand/icons/VIEW_NEW.svg'
							label={translate('New')}
							description={translate('Create a new Interaction from scratch')}
							onClick={() => setOperation(Operation.NEW_DATA_VISUALIZATION)}
						/>
					</Flex>
				</Flex>
			) : null}
			{operation === Operation.EXISTING_DATA_VISUALIZATION ? <ExistingDataVisualization onGoBack={handleOnGoBack} /> : null}
			{operation === Operation.NEW_DATA_VISUALIZATION ? <NewDataVisualization onGoBack={handleOnGoBack} /> : null}
		</>
	);
};
