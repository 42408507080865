import React, { useEffect, useRef } from 'react';
import { Chrome, ColorResult } from '@uiw/react-color';
import { Box, Square, useDisclosure, useOutsideClick } from '@chakra-ui/react';

enum ChromeInputType {
	HEXA = 'hexa',
}

interface Props {
	value: string;
	onChange: (colorHex: string) => void;
}

/**
 * Input adaptor for a color picker
 *
 * https://uiwjs.github.io/react-color/
 */
export const ColorInput: React.FC<Props> = ({ value, onChange }) => {
	const { isOpen, onToggle, onClose } = useDisclosure({ defaultIsOpen: false });
	const wrapperRef = useRef<HTMLDivElement>(null);

	useOutsideClick({
		ref: wrapperRef,
		handler: () => {
			onClose();
		},
	});

	const handleOnChange = (color: ColorResult) => {
		onChange(color.hex);
	};

	// Add useEffect to handle Escape key press
	useEffect(() => {
		const handleEscape = (event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				onClose();
			}
		};

		// Add event listener
		document.addEventListener('keydown', handleEscape);

		// Cleanup
		return () => {
			document.removeEventListener('keydown', handleEscape);
		};
	}, [onClose]); // Ensuring effect runs only if onClose changes

	return (
		<Box>
			<Square size='40px' border='2px' mr='8px' borderColor='brand' bg={value} color={value} onClick={onToggle} _hover={{ cursor: 'pointer' }} />
			{isOpen ? (
				<Box id='color-picker' position='absolute' zIndex={1} ref={wrapperRef}>
					<Chrome onChange={handleOnChange} color={value} inputType={ChromeInputType.HEXA} />
				</Box>
			) : null}
		</Box>
	);
};
