import get from 'lodash/get';
import { translations } from './translation-dictionary.ts';

type Language = keyof typeof translations;
type Translation = (typeof translations)['en']['translation'];
export type TranslationKeys = keyof Translation;

export function getLanguage(): Language {
	const language = navigator.language;
	return language.toLowerCase().includes('es') ? 'es' : 'en';
}

export type TranslatableValue = {
	[lang in Language]: string;
};

export function getTranslatedValue(translatableValue: TranslatableValue): string {
	return translatableValue[getLanguage()];
}

export function translate(key: TranslationKeys) {
	const language = getLanguage();
	return get(translations, `${language}.translation.${key}`, key);
}
