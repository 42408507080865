import ApiClient from '../../../apiClient';
import { InteractionTypeT } from '../../../types/Interaction.ts';
import { VisualizationTypeT } from '../../../types/Visualization.ts';
import { StaticContentTypeT } from '../../../types/StaticContent.ts';

interface NewElementResult {
	slideId: number;
}

interface NewInteractionInput {
	title: string;
	type: InteractionTypeT;
}

export const createInteraction = async (presentationId: string, newInteraction: NewInteractionInput): Promise<NewElementResult> => {
	return (await ApiClient.getInstance().post(`/author-app/presentations/${presentationId}/interactions`, newInteraction)).data;
};

interface NewVisualizationInput {
	title: string;
	type: VisualizationTypeT;
	existingInteractionId?: string;
	newInteraction?: NewInteractionInput;
}

export const createVisualization = async (presentationId: string, newVisualization: NewVisualizationInput): Promise<NewElementResult> => {
	return (await ApiClient.getInstance().post(`/author-app/presentations/${presentationId}/visualizations`, newVisualization)).data;
};

//=====
interface CreateStaticContentInput {
	title: string;
	type: StaticContentTypeT;
}

export const createStaticContent = async (presentationId: string, input: CreateStaticContentInput): Promise<NewElementResult> => {
	return (await ApiClient.getInstance().post(`/author-app/presentations/${presentationId}/static-content`, input)).data;
};
