import { Box, Flex } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useInvalidatePresentationQuery } from '../../../presentation/hooks/usePresentation.ts';
import { useUpdateVisualization } from '../../hooks/useVisualization.ts';
import { saveComicVisualization } from '../../service/VisualizationService.ts';
import { AUTO_SAVE_DEBOUNCE_TIME, INPUT_DESCRIPTION_MAX_LENGHT, INPUT_TITLE_MAX_LENGHT } from '../../../../types/Constants.ts';
import { ElementTypeT } from '../../../../types/Presentation.ts';
import { ComicConfigurationT, VisualizationT } from '../../../../types/Visualization.ts';
import { InputSubtitle } from '../../../commons/components/InputSubtitle';
import { InputTitle } from '../../../commons/components/InputTitle';
import { VisualizationInteractionCard } from '../VisualizationInteractionCard';
import debounce from 'lodash/debounce';
import { ElementConfigurationTopBar } from '../../../commons/components/ElementConfigurationTopBar';
import { SlideSettingsInput } from '../../../presentation/components/SlideSettingsInput';

interface Props {
	presentationId: string;
	visualization: VisualizationT;
}

export const ComicVisualization: React.FC<Props> = ({ presentationId, visualization }) => {
	const invalidatePresentation = useInvalidatePresentationQuery(presentationId);

	const updateVisualization = useUpdateVisualization(presentationId, visualization.id, (data: ComicConfigurationT) =>
		saveComicVisualization(presentationId, visualization.id, data)
	);

	// const conf = visualization.configuration as ComicConfigurationT

	const { register, handleSubmit, watch } = useForm<ComicConfigurationT>({
		defaultValues: {
			title: visualization.title,
			description: visualization.description,
		},
	});

	const onSubmit: SubmitHandler<ComicConfigurationT> = async (data) => {
		updateVisualization.mutate(data);
	};

	const debouncedSubmit = debounce(handleSubmit(onSubmit), AUTO_SAVE_DEBOUNCE_TIME);

	useEffect(() => {
		const subscription = watch(() => {
			debouncedSubmit();
		});
		return () => {
			subscription.unsubscribe();
			invalidatePresentation();
		};
	}, [watch]);

	return (
		<>
			<ElementConfigurationTopBar presentationId={presentationId} element={visualization} elementType={ElementTypeT.VISUALIZATION} />
			<VisualizationInteractionCard interaction={visualization.interaction} />
			<form onSubmit={handleSubmit(onSubmit)}>
				<InputTitle
					title={watch('title')}
					{...register('title', {
						maxLength: INPUT_TITLE_MAX_LENGHT,
						required: true,
					})}
				/>
				<Flex flexDirection='column'>
					<InputSubtitle
						title={watch('description')}
						{...register('description', {
							maxLength: INPUT_DESCRIPTION_MAX_LENGHT,
						})}
					/>
				</Flex>
				<Box mt={4}>
					<SlideSettingsInput presentationId={presentationId} slide={visualization.slide} />
				</Box>
			</form>
		</>
	);
};
