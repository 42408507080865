import { ElementSlideT } from './Slide';

export interface InteractionT {
	id: string;
	title: string;
	description: string;
	required: boolean;
	type: InteractionTypeT;
	configuration: InteractionConfigurationT;
	slide: ElementSlideT;
}

export enum InteractionTypeT {
	NUMBER = 'NUMBER',
	OPEN_ENDED = 'OPEN_ENDED',
	OPEN_ENDED_LONG = 'OPEN_ENDED_LONG',
	MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
	ICONIC = 'ICONIC',
	CATEGORISED = 'CATEGORISED',
	VALUATION = 'VALUATION',
	IMAGE = 'IMAGE',
	DRAWING = 'DRAWING',
	LINK = 'LINK',
}

export interface InteractionConfigurationT {
	id: number;
	title: string;
	description: string;
	required: boolean;
}

export interface OpenEndedInteractionConfigurationT extends InteractionConfigurationT {
	answersRequired: number;
	maximumAnswers: number;
}

export interface NumberInteractionConfigurationT extends InteractionConfigurationT {
	valueReference: string;
	minValue: number;
	maxValue: number;
}

export interface ValuationConfigurationT extends InteractionConfigurationT {
	minValue: number;
	maxValue: number;
	statements: ValuationConfigurationStatementEntry[];
}

export interface ValuationConfigurationStatementEntry {
	statement: string;
	color: string;
}

export interface MultipleChoiceConfigurationT extends InteractionConfigurationT {
	multipleAnswer: boolean;
	allowOthers: boolean;
	options: string[];
}

export interface CategorisedConfigurationT extends InteractionConfigurationT {
	categories: CategorisedConfigurationCategoryEntry[];
	maximumAnswers: number;
}

export interface CategorisedConfigurationCategoryEntry {
	category: string;
	color: string;
}

export interface ImageInteractionConfigurationT extends InteractionConfigurationT {
	longAnswer: boolean;
}

export interface IconicInteractionConfigurationT extends InteractionConfigurationT {
	longAnswer: boolean;
}

export interface DrawingInteractionConfigurationT extends InteractionConfigurationT {
	longAnswer: boolean;
}

export interface LinkInteractionConfigurationT extends InteractionConfigurationT {
	openInNewTab: boolean;
}
