import { FormControl, Input } from '@chakra-ui/react';
import React, { forwardRef } from 'react';
import isEmpty from 'lodash/isEmpty';
import { INPUT_TITLE_MAX_LENGHT, INPUT_TITLE_MIN_LENGHT } from '../../../../types/Constants.ts';

type Props = {
	title: string;
} & React.InputHTMLAttributes<HTMLInputElement>;
export type Ref = HTMLInputElement;

export const InputBasic = forwardRef<Ref, Props>(({ title, ...props }, ref) => {
	const isEmptyOrUntitled = isEmpty(title) || title === 'Untitled';
	const border = !isEmptyOrUntitled ? 'none' : undefined;

	return (
		<FormControl variant='floating' my={2}>
			<Input
				border={border}
				boxShadow={border}
				fontSize={22}
				fontWeight='bold'
				{...props}
				size='lg'
				placeholder='Title'
				data-peer
				required
				minLength={INPUT_TITLE_MIN_LENGHT}
				maxLength={INPUT_TITLE_MAX_LENGHT}
				ref={ref}
			/>
		</FormControl>
	);
});

InputBasic.displayName = 'InputBasic';
