import { useInvalidatePresentationQuery } from '../../../presentation/hooks/usePresentation.ts';
import { StaticContentT } from '../../../../types/StaticContent.ts';
import { SubmitHandler, useForm } from 'react-hook-form';
import debounce from 'lodash/debounce';
import { AUTO_SAVE_DEBOUNCE_TIME, INPUT_DESCRIPTION_MAX_LENGHT, INPUT_TITLE_MAX_LENGHT } from '../../../../types/Constants.ts';
import { useEffect } from 'react';
import { ElementConfigurationTopBar } from '../../../commons/components/ElementConfigurationTopBar';
import { ElementTypeT } from '../../../../types/Presentation.ts';
import { Box, Flex, FormControl, Icon, Tab, TabList, TabPanel, TabPanels, Tabs, Text, Textarea } from '@chakra-ui/react';
import { PiTextAlignJustifyFill } from 'react-icons/pi';
import { translate } from '../../../../translate';
import { IoSettingsSharp } from 'react-icons/io5';
import { InputTitle } from '../../../commons/components/InputTitle';
import { InputSubtitle } from '../../../commons/components/InputSubtitle';
import { SlideSettingsInput } from '../../../presentation/components/SlideSettingsInput';

export interface BasicContentTypeFormValues {
	title: string;
	description: string;
	text: string;
}

interface Props {
	presentationId: string;
	content: StaticContentT;
	textField?: {
		value: BasicContentTypeFormValues['text'];
		label?: string;
		placeholder?: string;
		required?: boolean;
		minLength?: number;
		maxLength?: number;
	};
	onSubmit: (data: BasicContentTypeFormValues) => void;
}

export function BasicContentTypeForm({ presentationId, content, textField, onSubmit }: Props) {
	const invalidatePresentation = useInvalidatePresentationQuery(presentationId);

	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm<BasicContentTypeFormValues>({
		defaultValues: {
			title: content.title,
			description: content.description,
			text: textField?.value,
		},
		shouldFocusError: false,
	});

	const handleOnSubmit: SubmitHandler<BasicContentTypeFormValues> = async (data) => {
		onSubmit(data);
	};

	const debouncedSubmit = debounce(handleSubmit(handleOnSubmit), AUTO_SAVE_DEBOUNCE_TIME);
	useEffect(() => {
		const subscription = watch(() => {
			debouncedSubmit();
		});
		return () => {
			subscription.unsubscribe();
			invalidatePresentation();
		};
	}, [watch]);

	return (
		<>
			<ElementConfigurationTopBar presentationId={presentationId} element={content} elementType={ElementTypeT.STATIC_CONTENT} />
			<form>
				<Tabs variant='line' my={4}>
					<TabList>
						<Tab fontSize={20}>
							<Icon as={PiTextAlignJustifyFill} pr={2} />
							{translate('Content')}
						</Tab>
						<Tab fontSize={20}>
							<Icon as={IoSettingsSharp} pr={2} />
							{translate('Settings')}
						</Tab>
					</TabList>
					<TabPanels>
						<TabPanel>
							<Flex flexDirection='column'>
								<InputTitle
									title={watch('title')}
									{...register('title', {
										maxLength: INPUT_TITLE_MAX_LENGHT,
										required: true,
									})}
								/>
							</Flex>
							<Flex flexDirection='column'>
								<InputSubtitle
									title={watch('description')}
									{...register('description', {
										maxLength: INPUT_DESCRIPTION_MAX_LENGHT,
									})}
								/>
							</Flex>
							{textField ? (
								<Flex flexDirection='column' my={8}>
									<Text fontSize='xl'>{textField.label}</Text>
									<FormControl isInvalid={!!errors.text} my={4}>
										<Textarea
											rows={8}
											placeholder={textField.placeholder}
											minLength={textField.minLength}
											maxLength={textField.maxLength}
											required={textField.required}
											size='lg'
											{...register(`text`, {
												required: textField.required,
												minLength: textField.minLength,
												maxLength: textField.maxLength,
											})}
										/>
									</FormControl>
								</Flex>
							) : null}
						</TabPanel>
						<TabPanel>
							<Box mt={4}>
								<SlideSettingsInput presentationId={presentationId} slide={content.slide} />
							</Box>
						</TabPanel>
					</TabPanels>
				</Tabs>
			</form>
		</>
	);
}
