import React, { useState } from 'react';
import { Entry } from '../../model/entry.ts';
import { Box, IconButton } from '@chakra-ui/react';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa6';
import { useModerateEntry } from '../../hooks/useModerateEntry.ts';
import { BasicPresentationT } from '../../../../types/Presentation.ts';
import { InteractionT } from '../../../../types/Interaction.ts';

interface Props {
	presentationId: BasicPresentationT['id'];
	interactionId: InteractionT['id'];
	entry: Entry;
}

export const ModerateEntryInput: React.FC<Props> = ({ presentationId, interactionId, entry }) => {
	const [isHidden, setIsHidden] = useState(entry.hidden);
	const mutation = useModerateEntry(presentationId, interactionId);

	const handleHide = () => {
		mutation.mutate({
			entryId: entry.id,
			hidden: false,
		});
		setIsHidden(false);
	};

	const handleShow = () => {
		mutation.mutate({
			entryId: entry.id,
			hidden: true,
		});
		setIsHidden(true);
	};

	return (
		<Box>
			{isHidden ? (
				<IconButton isLoading={mutation.isPending} my={0} aria-label='Display' icon={<FaRegEyeSlash />} color='gray.400' onClick={handleHide} />
			) : (
				<IconButton isLoading={mutation.isPending} my={0} aria-label='Hide' icon={<FaRegEye />} color='brand' onClick={handleShow} />
			)}
		</Box>
	);
};
