import React from 'react';
import { Button, Flex, FormControl, FormErrorMessage, Input } from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { NewElementHeader } from '../../../../../../../commons/components/NewElementHeader';
import { translate } from '../../../../../../../../translate';

interface Props {
	onTitleSubmit: (interactionTitle: string) => void;
	onGoBack: () => void;
}

type FormValues = {
	interactionTitle: string;
};

export const NewInteractionInput: React.FC<Props> = ({ onTitleSubmit, onGoBack }) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm<FormValues>({
		defaultValues: {
			interactionTitle: '',
		},
	});

	const handleOnClose = () => {
		reset();
		onGoBack();
	};

	const onSubmit: SubmitHandler<FormValues> = async (data) => {
		onTitleSubmit(data.interactionTitle);
		reset();
	};
	return (
		<Flex flexDirection='column' width='80%'>
			<NewElementHeader title={translate('Interaction title')} onGoBack={handleOnClose} />
			<Flex as='form' flexDirection='column' onSubmit={handleSubmit(onSubmit)}>
				<FormControl isInvalid={!!errors.interactionTitle}>
					<Input
						id='interactionTitle'
						placeholder={translate('Type your interaction title here')}
						size='xl'
						autoComplete='off'
						{...register('interactionTitle', {
							required: translate('This field is required'),
							minLength: {
								value: 3,
								message: translate('Title must be at least 3 characters long'),
							},
						})}
						autoFocus={true}
					/>
					{errors.interactionTitle ? <FormErrorMessage>{errors.interactionTitle?.message}</FormErrorMessage> : null}
				</FormControl>
				<Flex alignSelf='center'>
					<Button variant='submit' type='submit'>
						{translate('Next')}
					</Button>
				</Flex>
			</Flex>
		</Flex>
	);
};
