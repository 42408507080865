import { Td, Tr } from '@chakra-ui/react';
import { DrawingEntry } from '../../../model/entry.ts';
import { ModerateEntryInput } from '../../ModerateEntryInput';
import { BasicPresentationT } from '../../../../../types/Presentation.ts';
import { InteractionT } from '../../../../../types/Interaction.ts';

interface Props {
	presentationId: BasicPresentationT['id'];
	interactionId: InteractionT['id'];
	entries: DrawingEntry[];
}

export const DrawingTableRow = ({ presentationId, interactionId, entries }: Props) => {
	return (
		<>
			{entries.map((entry) => (
				<Tr key={entry.id}>
					<Td>
						<ModerateEntryInput presentationId={presentationId} interactionId={interactionId} entry={entry} />
					</Td>
					<Td>
						<div style={{ width: '60px', height: '60px' }}>
							<img src={`data:image/svg+xml;base64,${btoa(decodeURIComponent(encodeURIComponent(entry.drawing)))}`} alt='SVG Content' style={{ width: '100%', height: '100%' }} />
						</div>
					</Td>
					<Td>{entry.heading}</Td>
				</Tr>
			))}
		</>
	);
};
