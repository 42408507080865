import { Card, CardProps, Flex, Text } from '@chakra-ui/react';
import truncate from 'lodash/truncate';
import React from 'react';
import { VisualizationT } from '../../../../types/Visualization.ts';
import { mapElementTypeToWords } from '../../../../util/InteractionUtils.ts';
import { translate } from '../../../../translate';

interface Props {
	interaction: VisualizationT['interaction'];
}

//TODO: FIXME (...): <tbody> cannot appear as a child of <div>.
export const VisualizationInteractionCard: React.FC<Props & CardProps> = ({ interaction }) => {
	return (
		<Flex as={Card} size='md' my={6} backgroundColor='colorScheme.50' gap={6} p={4}>
			<Flex flexDirection='row'>
				<Text fontSize={14} fontWeight='bold' textTransform='uppercase'>
					{translate('Interaction you are visualizing')}
				</Text>
			</Flex>
			<Flex flexDirection='row' gap={6}>
				<Flex flexDirection='column' flex={1} alignItems='flex-start'>
					<Flex flexDirection='row' justifyContent='flex-start' gap={2}>
						{/*<Image src='/assets/brand/icons/PRESENTATION.svg' alt='Presentation' boxSize='8' />*/}
						<Text>Presentation</Text>
					</Flex>
					<Flex key={interaction.presentationId}>
						<Text fontWeight='bold' fontSize='18'>
							{truncate(interaction.presentationTitle, { length: 100 })}
						</Text>
					</Flex>
				</Flex>

				<Flex flexDirection='column' flex={1} alignItems='flex-start'>
					<Flex flexDirection='row' justifyContent='flex-start' gap={2}>
						{/*<InteractionIcon type={interaction.type} />*/}
						<Text>{mapElementTypeToWords(interaction.type)} Interaction</Text>
					</Flex>
					<Flex key={interaction.id}>
						<Text fontWeight='bold' fontSize='18'>
							{truncate(interaction.title, { length: 100 })}
						</Text>
					</Flex>
				</Flex>
			</Flex>
		</Flex>
	);
};
