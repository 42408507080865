import { Card } from '../../../../../commons/components/Card';
import { motion } from 'framer-motion';
import { FlexProps, forwardRef } from '@chakra-ui/react';

export const SlideMiniature = forwardRef<FlexProps, 'div'>((props, ref) => (
	<Card
		h={150}
		minHeight={150}
		maxHeight={150}
		w={150}
		minWidth={150}
		maxWidth={150}
		my={2}
		justifyContent='center'
		alignItems='center'
		as={motion.div}
		_hover={{ cursor: 'pointer' }}
		whileTap={{ scale: 1.1 }}
		ref={ref}
		{...props}
	/>
));
