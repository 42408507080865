import { BasicPresentationT } from '../../../types/Presentation.ts';
import { InteractionT } from '../../../types/Interaction.ts';
import { moderateEntry, ModerateEntryInput } from '../service/EntryService.ts';
import { useMutation } from '@tanstack/react-query';

export const useModerateEntry = (presentationId: BasicPresentationT['id'], interactionId: InteractionT['id']) => {
	return useMutation({
		mutationFn: (input: ModerateEntryInput) => {
			return moderateEntry(presentationId, interactionId, input);
		},
	});
};
