import { useQuery } from '@tanstack/react-query';
import { getAllVisualizationsByInteraction } from '../service/InteractionService.ts';

const key = (presentationId: string, interactionId: string) => ['presentation', presentationId, 'interactions', interactionId, 'visualizations'];

export const useVisualizationsByInteraction = (presentationId: string, interactionId: string, enabled: boolean) => {
	return useQuery({
		queryKey: key(presentationId, interactionId),
		queryFn: () => getAllVisualizationsByInteraction(presentationId, interactionId),
		refetchOnWindowFocus: false,
		enabled: enabled,
	});
};
