import { Flex, FormControl, IconButton, Input, InputGroup, InputRightAddon, Tag, TagCloseButton, TagLabel } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import union from 'lodash/union';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { HiPlus } from 'react-icons/hi';
import { TAG_SELECTOR_NAME_MAX_LENGTH } from '../../../../types/Constants.ts';

interface Props {
	value: string[];
	name: string;
	placeholder?: string;
	onChange: (data: string[]) => void;
	onBlur: () => void;
	minEntries?: number;
	maxEntries?: number;
}

interface FormValue {
	entry: string;
}

export const TagSelector: React.FC<Props> = ({ value, onChange, placeholder = '', maxEntries = 1, minEntries = 8 }) => {
	const { register, handleSubmit, resetField } = useForm<FormValue>();
	const canAdd = () => value.length < maxEntries!;
	const canRemove = () => value.length > minEntries!;

	const onSubmit: SubmitHandler<FormValue> = async (data) => {
		if (canAdd() && !isEmpty(data.entry)) {
			onChange(union(value, [data.entry]));
			resetField('entry');
		}
	};

	const onRemove = (entry: string) => () => {
		if (canRemove()) {
			const removed = value.filter((v) => v !== entry);
			onChange(removed);
		}
	};

	return (
		<Flex flexDirection='column' mt={4}>
			<Flex>
				<InputGroup>
					<FormControl>
						<Input
							mb={4}
							type='text'
							size='lg'
							placeholder={placeholder}
							minLength={3}
							maxLength={TAG_SELECTOR_NAME_MAX_LENGTH}
							{...register('entry', {
								minLength: 3,
								maxLength: TAG_SELECTOR_NAME_MAX_LENGTH,
							})}
							data-peer
						/>
					</FormControl>

					<InputRightAddon borderBottomColor='white'>
						<IconButton
							bg='brand'
							aria-label='Search database'
							icon={<HiPlus />}
							type='submit'
							disabled={!canAdd()}
							onClick={handleSubmit(onSubmit)}
							borderRadius={{}}
							borderRightRadius='full'
						/>
					</InputRightAddon>
				</InputGroup>
			</Flex>
			<Flex gap={1}>
				{value!.map((s) => (
					<Tag key={s} size='lg' borderRadius='full'>
						<TagLabel>{s}</TagLabel>
						<TagCloseButton onClick={onRemove(s)} />
					</Tag>
				))}
			</Flex>
		</Flex>
	);
};
