import { ThemeT } from '../model/ThemeT.ts';

export function colorSheme(colors?: ThemeT['colors']): string[] {
	if (!colors) return [];
	const { colorScheme } = colors;
	return [
		colorScheme.color50,
		colorScheme.color100,
		colorScheme.color200,
		colorScheme.color300,
		colorScheme.color400,
		colorScheme.color500,
		colorScheme.color600,
		colorScheme.color700,
		colorScheme.color800,
		colorScheme.color900,
	];
}
