import { SubmitHandler, useForm } from 'react-hook-form';
import { Button, Flex, FormControl, FormErrorMessage, Input } from '@chakra-ui/react';
import { NewElementHeader } from '../../../commons/components/NewElementHeader';
import { translate } from '../../../../translate';
import { INPUT_TITLE_MAX_LENGHT } from '../../../../types/Constants.ts';

interface Props {
	onSubmit: (values: NewStaticContentInputFormValues) => void;
	onGoBack: () => void;
}

export type NewStaticContentInputFormValues = {
	title: string;
};

export function NewStaticContentInput({ onSubmit, onGoBack }: Props) {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm<NewStaticContentInputFormValues>({
		defaultValues: {
			title: '',
		},
	});

	const handleOnClose = () => {
		reset();
		onGoBack();
	};

	const handleOnSubmit: SubmitHandler<NewStaticContentInputFormValues> = async (data) => {
		onSubmit(data);
		reset();
	};

	return (
		<Flex flexDirection='column' width='80%'>
			<NewElementHeader title={translate('Title')} onGoBack={handleOnClose} />
			<Flex as='form' flexDirection='column' onSubmit={handleSubmit(handleOnSubmit)}>
				<FormControl isInvalid={!!errors.title}>
					<Input
						id='title'
						placeholder={translate('Title')}
						size='xl'
						autoComplete='off'
						{...register('title', {
							required: translate('This field is required'),
							minLength: {
								value: 3,
								message: translate('Title must be at least 3 characters long'),
							},
							maxLength: {
								value: INPUT_TITLE_MAX_LENGHT,
								message: 'too long',
							},
						})}
						autoFocus={true}
					/>
					{errors.title ? <FormErrorMessage>{errors.title?.message}</FormErrorMessage> : null}
				</FormControl>
				<Flex alignSelf='center'>
					<Button variant='submit' type='submit'>
						{translate('Next')}
					</Button>
				</Flex>
			</Flex>
		</Flex>
	);
}
