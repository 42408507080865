import React from 'react';
import { useParams } from 'react-router-dom';
import { EntryList } from '../../modules/entries/components/EntryList';

interface Props {}

export const EntryManagementPage: React.FC<Props> = () => {
	const { presentationId, interactionId } = useParams();

	return <EntryList presentationId={presentationId!} interactionId={interactionId!} />;
};
