import axios from 'axios';

export interface RestExceptionInfo {
	status: number;
	message: string;
}

export function extractRestExceptionInfo(error: unknown): RestExceptionInfo | undefined {
	if (axios.isAxiosError(error)) {
		const data = error.response?.data;
		if (data && typeof data === 'object' && 'status' in data && 'message' in data) {
			return data as RestExceptionInfo;
		}
	}
	return undefined;
}
