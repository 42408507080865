import { Tag, Td, Tr } from '@chakra-ui/react';
import { CategorisedEntry } from '../../../model/entry.ts';
import { ModerateEntryInput } from '../../ModerateEntryInput';
import { BasicPresentationT } from '../../../../../types/Presentation.ts';
import { InteractionT } from '../../../../../types/Interaction.ts';

interface Props {
	presentationId: BasicPresentationT['id'];
	interactionId: InteractionT['id'];
	entries: CategorisedEntry[];
}

export const CategorisedTableRow = ({ presentationId, interactionId, entries }: Props) => {
	return (
		<>
			{entries.map((entry) => (
				<Tr key={entry.id}>
					<Td>
						<ModerateEntryInput presentationId={presentationId} interactionId={interactionId} entry={entry} />
					</Td>

					<Td>{entry.key}</Td>
					<Td>
						{entry.entries.map((e) => (
							<Tag mx={1} key={e} backgroundColor='colorScheme.100' textColor='textColor' size='lg'>
								{e}
							</Tag>
						))}
					</Td>
				</Tr>
			))}
		</>
	);
};
