import React, { useEffect } from 'react';
import { Box, Flex, FormControl, FormLabel, Icon, IconButton, Input, InputGroup, InputRightElement, Switch, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
import { Controller, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { CategorisedConfigurationCategoryEntry, CategorisedConfigurationT, InteractionT } from '../../../../types/Interaction.ts';
import { useInvalidatePresentationQuery } from '../../../presentation/hooks/usePresentation.ts';
import { saveCategorisedAnswerInteraction } from '../../service/InteractionService.ts';
import { InputTitle } from '../../../commons/components/InputTitle';
import { InputSubtitle } from '../../../commons/components/InputSubtitle';
import { useUpdateInteraction } from '../../hooks/useInteraction.ts';
import { ElementTypeT } from '../../../../types/Presentation.ts';
import {
	AUTO_SAVE_DEBOUNCE_TIME,
	CATEGORISED_ANSWER_MAX_COUNT_CATEGORIES,
	CATEGORISED_ANSWER_MIN_COUNT_CATEGORIES,
	INPUT_DESCRIPTION_MAX_LENGHT,
	INPUT_TITLE_MAX_LENGHT,
	MULTIPLE_CHOICE_OPTIONS_MAX_LENGTH,
	MULTIPLE_CHOICE_OPTIONS_MIN_LENGTH,
} from '../../../../types/Constants.ts';
import debounce from 'lodash/debounce';
import { ElementConfigurationTopBar } from '../../../commons/components/ElementConfigurationTopBar';
import { PiTextAlignJustifyFill } from 'react-icons/pi';
import { MdAdd, MdClose } from 'react-icons/md';
import { IoSettingsSharp } from 'react-icons/io5';
import { SlideSettingsInput } from '../../../presentation/components/SlideSettingsInput';
import { ColorInput } from '../../../commons/components/ColorInput';
import { translate } from '../../../../translate';
import { useThemeForPresentation } from '../../../theming/hooks/useThemeForPresentation.ts';
import isEmpty from 'lodash/isEmpty';
import { colorSheme } from '../../../theming/service/ThemeColorService.ts';

interface Props {
	presentationId: string;
	interaction: InteractionT;
}

export const CategorisedAnswerInteraction: React.FC<Props> = ({ presentationId, interaction }) => {
	const conf = interaction.configuration as CategorisedConfigurationT;
	const { data: theme } = useThemeForPresentation(presentationId);
	const invalidatePresentation = useInvalidatePresentationQuery(presentationId);
	const updateInteraction = useUpdateInteraction(presentationId, interaction.id, (data: CategorisedConfigurationT) =>
		saveCategorisedAnswerInteraction(presentationId, interaction.id, data)
	);

	const {
		register,
		handleSubmit,
		watch,
		control,
		formState: { errors },
	} = useForm<CategorisedConfigurationT>({
		defaultValues: {
			title: interaction.title,
			description: interaction.description,
			categories: conf.categories,
			maximumAnswers: conf.maximumAnswers,
			required: interaction.required,
		},
	});

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'categories',
		rules: {
			minLength: CATEGORISED_ANSWER_MIN_COUNT_CATEGORIES,
			maxLength: CATEGORISED_ANSWER_MAX_COUNT_CATEGORIES,
			required: true,
		},
	});

	const themeColorScheme = colorSheme(theme?.colors);

	const addEmptyCategory = () => {
		const currentCategories = watch('categories');
		const existingEmptyCategory = currentCategories.filter((cat) => isEmpty(cat.category));
		if (existingEmptyCategory.length === 0) {
			const newColorIndex = (currentCategories.length + 1) % themeColorScheme.length; // Make a ring
			const category: CategorisedConfigurationCategoryEntry = {
				category: '',
				color: themeColorScheme[newColorIndex],
			};
			append(category);
		}
	};

	const onSubmit: SubmitHandler<CategorisedConfigurationT> = async (data) => {
		const categories = data.categories.filter((cat) => !isEmpty(cat.color) && !isEmpty(cat.category));
		updateInteraction.mutate({
			...data,
			categories: categories,
		});
	};

	const debouncedSubmit = debounce(handleSubmit(onSubmit), AUTO_SAVE_DEBOUNCE_TIME);

	useEffect(() => {
		const subscription = watch(() => {
			debouncedSubmit();
		});
		return () => {
			subscription.unsubscribe();
			invalidatePresentation();
		};
	}, [watch, handleSubmit]);

	return (
		<>
			<ElementConfigurationTopBar presentationId={presentationId} element={interaction} elementType={ElementTypeT.INTERACTION} />
			<form onSubmit={handleSubmit(onSubmit)}>
				<Tabs variant='line' my={4}>
					<TabList>
						<Tab fontSize={20}>
							<Icon as={PiTextAlignJustifyFill} pr={2} />
							{translate('Content')}
						</Tab>
						<Tab fontSize={20}>
							<Icon as={IoSettingsSharp} pr={2} />
							{translate('Settings')}
						</Tab>
					</TabList>

					<TabPanels>
						<TabPanel>
							<InputTitle
								title={watch('title')}
								{...register('title', {
									maxLength: INPUT_TITLE_MAX_LENGHT,
									required: true,
								})}
							/>
							<InputSubtitle
								title={watch('description')}
								{...register('description', {
									maxLength: INPUT_DESCRIPTION_MAX_LENGHT,
								})}
							/>
							<FormControl mt={8}>
								<Text size='lg'> {translate('Categories')}</Text>
								<Box mr={4}>
									{fields.map((field, index) => (
										<Flex w='full' flexDirection='row' key={field.id} alignItems='center' gap={2}>
											<Controller
												name={`categories.${index}.color`}
												control={control}
												rules={{ required: false }}
												render={({ field }) => <ColorInput value={field.value} onChange={field.onChange} />}
											/>
											<Flex w='100%'>
												<FormControl isInvalid={!!errors.categories?.[index]}>
													<InputGroup size='lg' key={field.id} my={4}>
														<Input
															id={field.id}
															size='lg'
															placeholder=' '
															data-peer
															minLength={MULTIPLE_CHOICE_OPTIONS_MIN_LENGTH}
															maxLength={MULTIPLE_CHOICE_OPTIONS_MAX_LENGTH}
															required
															{...register(`categories.${index}.category` as const, {
																required: true,
																minLength: MULTIPLE_CHOICE_OPTIONS_MIN_LENGTH,
																maxLength: MULTIPLE_CHOICE_OPTIONS_MAX_LENGTH,
															})}
														/>
														<InputRightElement width='0.5rem' px={6} onClick={() => remove(index)}>
															<IconButton rounded='50' aria-label='Delete' size='xs' icon={<Icon color='brand' as={MdClose} />} />
														</InputRightElement>
													</InputGroup>
												</FormControl>
											</Flex>
										</Flex>
									))}
								</Box>
								{fields.length < CATEGORISED_ANSWER_MAX_COUNT_CATEGORIES ? (
									<Flex justifyContent='center'>
										<IconButton rounded='50' bg='brand' aria-label='' onClick={addEmptyCategory} size='lg' w='8' icon={<Icon as={MdAdd} />} />
									</Flex>
								) : null}
							</FormControl>
						</TabPanel>

						<TabPanel>
							{/*<FormControl mt={8}>*/}
							{/*	<Input*/}
							{/*		id='maximumAnswers'*/}
							{/*		type='number'*/}
							{/*		size='lg'*/}
							{/*		data-peer*/}
							{/*		required*/}
							{/*		disabled*/}
							{/*		min={CATEGORISED_ANSWER_MIN_ANSWER}*/}
							{/*		max={CATEGORISED_ANSWER_MAX_ANSWER}*/}
							{/*		{...register('maximumAnswers', {*/}
							{/*			required: true,*/}
							{/*			min: CATEGORISED_ANSWER_MIN_ANSWER,*/}
							{/*			max: CATEGORISED_ANSWER_MAX_ANSWER,*/}
							{/*			disabled: true,*/}
							{/*		})}*/}
							{/*	/>*/}
							{/*	<FormLabel*/}
							{/*		htmlFor='maximumAnswers'*/}
							{/*		variant='floating'*/}
							{/*		size='lg'*/}
							{/*	>*/}
							{/*		Maximum number of answers*/}
							{/*	</FormLabel>*/}
							{/*</FormControl>*/}
							<FormControl display='flex' alignItems='center'>
								<Switch size='lg' id='required' {...register('required')} />
								<FormLabel htmlFor='required' fontSize='lg' mx={2}>
									{translate('Answer Required')}
								</FormLabel>
							</FormControl>
							<Box mt={4}>
								<SlideSettingsInput presentationId={presentationId} slide={interaction.slide} />
							</Box>
						</TabPanel>
					</TabPanels>
				</Tabs>
			</form>
		</>
	);
};
