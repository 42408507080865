import { useState } from 'react';
import { Button, Flex, useDisclosure, VStack } from '@chakra-ui/react';
import { ThemeList } from '../../components/ThemeList';
import { HiPlus } from 'react-icons/hi';
import { EditTheme } from '../../components/EditTheme';
import { CreateTheme } from '../../components/CreateTheme';
import { ThemeT } from '../../model/ThemeT.ts';
import { translate } from '../../../../translate';

interface Props {
	presentationId: string;
}

export const ThemeListPage = ({ presentationId }: Props) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [themeIdToEdit, setThemeIdToEdit] = useState<ThemeT['id']>();

	const handleOnEdit = (themeId: ThemeT['id']) => {
		setThemeIdToEdit(themeId);
		onOpen();
	};

	const handleOnCreate = () => {
		setThemeIdToEdit(undefined);
		onOpen();
	};

	return (
		<Flex width='100%' flexDirection='column' alignItems='center'>
			{themeIdToEdit && isOpen ? <EditTheme themeId={themeIdToEdit} onClose={onClose} /> : null}
			{!themeIdToEdit && isOpen ? <CreateTheme onClose={onClose} /> : null}
			<Flex alignSelf='flex-end'>
				<VStack>
					<Button rightIcon={<HiPlus />} onClick={handleOnCreate} variant='submit'>
						{translate('New theme')}
					</Button>
				</VStack>
			</Flex>
			<ThemeList presentationId={presentationId} onEdit={handleOnEdit} />
		</Flex>
	);
};
