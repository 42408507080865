import { useQuery } from '@tanstack/react-query';
import { getImageCollection } from './ImageCollectionService.ts';

const key = () => ['image-collections'];

export const useImageCollections = () => {
	return useQuery({
		queryKey: key(),
		queryFn: () => getImageCollection(),
		staleTime: Infinity,
		refetchOnWindowFocus: false,
	});
};
