import { Tag, Td, Tr } from '@chakra-ui/react';
import { OpenEndedLongEntry } from '../../../model/entry.ts';
import { ModerateEntryInput } from '../../ModerateEntryInput';
import { BasicPresentationT } from '../../../../../types/Presentation.ts';
import { InteractionT } from '../../../../../types/Interaction.ts';

interface Props {
	presentationId: BasicPresentationT['id'];
	interactionId: InteractionT['id'];
	entries: OpenEndedLongEntry[];
}

export const OpenEndedLongTableRow = ({ presentationId, interactionId, entries }: Props) => {
	return (
		<>
			{entries.map((entry) => (
				<Tr key={entry.id}>
					<Td>
						<ModerateEntryInput presentationId={presentationId} interactionId={interactionId} entry={entry} />
					</Td>
					<Td>
						<Tag mx={1} size='lg' backgroundColor='colorScheme.100' textColor='textColor'>
							{entry.entry}
						</Tag>
					</Td>
				</Tr>
			))}
		</>
	);
};
