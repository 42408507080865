import { getIconForElement, mapElementTypeToWords } from '../../../../util/InteractionUtils.ts';
import { ElementTypeT } from '../../../../types/Presentation.ts';
import { IconBox } from '../../../commons/components/IconBox';
import { StaticContentTypeT } from '../../../../types/StaticContent.ts';

interface Props {
	type: StaticContentTypeT;
	onSelect: (type: StaticContentTypeT) => void;
}

export function StaticContentPickerIconBox({ type, onSelect }: Props) {
	const staticContentIcons = getIconForElement(ElementTypeT.STATIC_CONTENT);
	const handleOnClick = () => {
		onSelect(type);
	};
	return <IconBox label={mapElementTypeToWords(type)} iconImageSrc={staticContentIcons(type)} onClick={handleOnClick} />;
}
