import { useImageCollections } from './useImageCollections.ts';
import { Loading } from '../../../Loading';
import { Alert, AlertIcon, AlertTitle, Flex, Heading, IconButton, Image, SimpleGrid, Text } from '@chakra-ui/react';
import { getTranslatedValue, translate } from '../../../../../../translate';
import { useState } from 'react';
import { ImageCollection } from './ImageCollectionService.ts';
import { MdOutlineArrowBackIos } from 'react-icons/md';

interface Props {
	onChange: (selectedImageUrl: string) => void;
}

export const ImageCollectionPicker = ({ onChange }: Props) => {
	const { data, isLoading, isError } = useImageCollections();
	const [selectedCollection, setSelectedCollection] = useState<ImageCollection | undefined>();

	if (isLoading) {
		return <Loading />;
	}

	if (isError) {
		return (
			<Alert status='error'>
				<AlertIcon />
				<AlertTitle>{translate('Error fetching collections')}</AlertTitle>
			</Alert>
		);
	}

	if (data && data.length > 0) {
		return (
			<>
				<SimpleGrid columns={4} spacing={10} display={!selectedCollection ? 'grid' : 'none'}>
					{data.map((d) => (
						<Flex key={d.id} flexDirection='column' _hover={{ cursor: 'pointer' }} onClick={() => setSelectedCollection(d)}>
							<Text m={4} fontSize='sm' color='white' position='absolute' backgroundColor='rgba(0, 0, 0, 0.8)' padding={2} borderRadius={4}>
								{getTranslatedValue(d.name)}
							</Text>
							<Image src={d.coverImage} alt={getTranslatedValue(d.name)} borderRadius='lg' />
						</Flex>
					))}
				</SimpleGrid>
				{selectedCollection && <Collection selectedCollection={selectedCollection} onSelectImage={onChange} onGoBack={() => setSelectedCollection(undefined)} />}
			</>
		);
	}

	return null;
};

interface CollectionProps {
	selectedCollection: ImageCollection;
	onGoBack: () => void;
	onSelectImage: (selectedImageUrl: string) => void;
}

const Collection = ({ selectedCollection, onSelectImage, onGoBack }: CollectionProps) => {
	return (
		<>
			<Flex alignItems='center' onClick={onGoBack} _hover={{ cursor: 'pointer' }}>
				<IconButton backgroundColor='transparent' textColor='brand' aria-label='back' borderRightRadius='0' my={0} mr={4} icon={<MdOutlineArrowBackIos />} />
				<Heading size='xs' textAlign='center' my={8}>
					{getTranslatedValue(selectedCollection.name)}
				</Heading>
			</Flex>
			<SimpleGrid columns={4} spacing={10}>
				{selectedCollection.imageBackgrounds.map((i) => (
					<Flex key={i} flexDirection='column' _hover={{ cursor: 'pointer' }} onClick={() => onSelectImage(i)}>
						<Image src={i} alt={getTranslatedValue(selectedCollection.name)} borderRadius='lg' />
					</Flex>
				))}
			</SimpleGrid>
		</>
	);
};
