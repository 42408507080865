import { tagAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tagAnatomy.keys);

const baseStyle = definePartsStyle({
	// define the part you're going to style
	container: {
		bg: 'brand',
		color: 'white',
	},
	label: {
		color: 'white',
	},
	closeButton: {
		color: 'white',
		backgroundColor: 'brand',
	},
});

export const tagTheme = defineMultiStyleConfig({ variants: { brand: baseStyle }, defaultProps: { variant: 'brand' } });
