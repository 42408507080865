import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { refreshElementPreview } from '../../presentation/components/ElementPreview/ElementPreview.tsx';
import { getInteractionDetails, searchInteraction } from '../service/InteractionService.ts';

const key = (presentationId: string, interactionId: string) => ['presentation', presentationId, 'interactions', interactionId];

export const useInteraction = (presentationId: string, interactionId: string) => {
	return useQuery({
		queryKey: key(presentationId, interactionId),
		queryFn: () => getInteractionDetails(presentationId, interactionId),
		refetchOnWindowFocus: false,
	});
};

export const useUpdateInteraction = (
	presentationId: string,
	interactionId: string,
	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	mutationFn: (interaction: any) => any
) => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: mutationFn,
		onSuccess: async () => {
			await queryClient.invalidateQueries({ queryKey: key(presentationId, interactionId) });
			refreshElementPreview();
		},
	});
};

const searchKey = (presentationId: string, query: string) => ['presentation', presentationId, 'interactions', 'search', query];

export const useSearchInteraction = (presentationId: string, query: string) => {
	return useQuery({
		queryKey: searchKey(presentationId, query),
		queryFn: () => searchInteraction(presentationId, query),
		staleTime: 5000,
		refetchOnWindowFocus: false,
	});
};
