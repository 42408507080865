import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { HideSlideInput } from './_/HideSlideInput';
import { SlideBackgroundImageInput } from './_/SlideBackgroundImageInput';
import { ElementSlideT } from '../../../../types/Slide.ts';
import { HideSlideTitleInput } from './_/HideSlideTitleInput';

interface Props {
	presentationId: string;
	slide: ElementSlideT;
}

export const SlideSettingsInput: React.FC<Props> = ({ presentationId, slide }) => {
	return (
		<Flex flexDirection='column' gap={4}>
			<Box>
				<HideSlideInput presentationId={presentationId} slide={slide} />
			</Box>
			<Box>
				<HideSlideTitleInput presentationId={presentationId} slide={slide} />
			</Box>
			<Box>
				<SlideBackgroundImageInput presentationId={presentationId} slide={slide} />
			</Box>
		</Flex>
	);
};
