import axios from 'axios';
import { TranslatableValue } from '../../../../../../translate';

const axiosClient = axios.create({
	baseURL: `${import.meta.env.VITE_API_BASE_URL}/image-api/collections`,
	timeout: 30000,
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		'X-API-KEY': import.meta.env.VITE_IMAGE_API_KEY,
	},
});

export interface ImageCollection {
	id: number;
	name: TranslatableValue;
	coverImage: string;
	images: string[];
	imageBackgrounds: string[];
}

export const getImageCollection = async (): Promise<ImageCollection[]> => {
	return (await axiosClient.get<ImageCollection[]>('')).data;
};
