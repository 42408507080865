import React from 'react';
import { useTheme } from '../../hooks/useTheme.ts';
import { ThemeForm } from '../ThemeForm';
import { ThemeT } from '../../model/ThemeT.ts';
import { Alert, AlertTitle, useToast } from '@chakra-ui/react';

interface Props {
	themeId: ThemeT['id'];
	onClose: () => void;
}

export const EditTheme: React.FC<Props> = ({ themeId, onClose }) => {
	const { data, isPending, isError } = useTheme(themeId);
	const toast = useToast();

	if (isError) {
		const id = 'themeNotFound';
		if (!toast.isActive(id)) {
			toast({
				id: id,
				title: 'Error when fetching a theme',
				description: '',
				status: 'error',
				duration: 3000,
				isClosable: true,
				position: 'top',
			});
		}
	}

	if (!isPending && !data) {
		return (
			<Alert status='info'>
				<AlertTitle>Theme not found</AlertTitle>
			</Alert>
		);
	}

	if (data) {
		return <ThemeForm theme={data} onClose={onClose} />;
	}

	return null;
};
