import { Center, Spinner } from '@chakra-ui/react';
import React from 'react';

interface Props {
	size?: string;
}

export const Loading: React.FC<Props> = ({ size = 'xl' }) => {
	return (
		<Center>
			<Spinner size={size} />
		</Center>
	);
};
