import React, { useEffect, useState } from 'react';
import { ElementSlideT } from '../../../../../../types/Slide.ts';
import { updateSlideTitleVisibility } from '../../../../service/PresentationService.ts';
import { FormControl, FormLabel, Switch } from '@chakra-ui/react';
import { refreshElementPreview } from '../../../ElementPreview/ElementPreview.tsx';
import { translate } from '../../../../../../translate';

interface Props {
	presentationId: string;
	slide: ElementSlideT;
}

export const HideSlideTitleInput: React.FC<Props> = ({ presentationId, slide }) => {
	const [isHidden, setIsHidden] = useState(slide.hideTitle);
	const doUpdateParam = async () => {
		const hideTitle = !isHidden;
		await updateSlideTitleVisibility(presentationId, slide.id, { hideTitle: hideTitle });
		setIsHidden(hideTitle);
		refreshElementPreview();
	};

	useEffect(() => {
		setIsHidden(slide.hideTitle);
	}, [slide.hideTitle]);

	return (
		<FormControl display='flex' alignItems='center'>
			<Switch size='lg' id={`HiddenSlideTitleInput/${slide.id}`} onChange={doUpdateParam} isChecked={isHidden} />
			<FormLabel htmlFor={`HiddenSlideTitleInput/${slide.id}`} fontSize='lg' mx={2}>
				{translate('Hide slide title')}
			</FormLabel>
		</FormControl>
	);
};
