import { Tag, TagLabel } from '@chakra-ui/react';
import React from 'react';
import { ElementTypeT } from '../../../../types/Presentation.ts';
import { InteractionTypeT } from '../../../../types/Interaction.ts';
import { VisualizationTypeT } from '../../../../types/Visualization.ts';
import { mapElementTypeToWords } from '../../../../util/InteractionUtils.ts';
import { StaticContentTypeT } from '../../../../types/StaticContent.ts';

interface Props {
	elementType: ElementTypeT;
	elementSubType: InteractionTypeT | VisualizationTypeT | StaticContentTypeT;
}

export const ElementType: React.FC<Props> = ({ elementSubType }) => (
	<Tag mb={2} size='lg' borderRadius='full' variant='solid' backgroundColor='colorScheme.100' color='brand' p={2} pr={6}>
		{/*<Image src={getIconForElement(elementType)(elementSubType)} boxSize='8' alt={`${elementSubType} ${elementType}`} />*/}
		<TagLabel ml={2} fontSize={22}>
			{mapElementTypeToWords(elementSubType)}
		</TagLabel>
	</Tag>
);
