import { useQuery } from '@tanstack/react-query';
import { getAllThemes } from '../service/ThemingService.ts';

export const allThemesKey = () => ['all-themes'];

export const useAllThemes = () => {
	return useQuery({
		queryKey: allThemesKey(),
		queryFn: () => getAllThemes(),
		refetchOnWindowFocus: false,
	});
};
