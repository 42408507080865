import React from 'react';
import { Flex, Heading, IconButton } from '@chakra-ui/react';
import { MdOutlineArrowBackIos } from 'react-icons/md';

interface Props {
	title: string;
	onGoBack: () => void;
}

export const NewElementHeader: React.FC<Props> = ({ title, onGoBack }) => {
	return (
		<Flex justifyContent='center' alignItems='center'>
			<IconButton backgroundColor='transparent' textColor='brand' aria-label='' borderRightRadius='0' my={0} mr={4} icon={<MdOutlineArrowBackIos />} onClick={onGoBack} />
			<Heading size='sm' textAlign='center'>
				{title}
			</Heading>
		</Flex>
	);
};
