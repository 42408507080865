import React from 'react';
import { useAuth } from '../../modules/auth/FirebaseAuthProvider/FirebaseAuthProvider.tsx';
import { Navigate } from 'react-router-dom';
import { Loading } from '../../modules/commons/components/Loading';

interface Props {
	children: React.ReactNode;
}

export const AuthenticationGuard: React.FC<Props> = ({ children }) => {
	const { isAuthenticated, isLoading } = useAuth();

	if (isLoading) {
		return <Loading />;
	}

	return isAuthenticated ? children : <Navigate to='/login' />;
};
