import React from 'react';
import { PresentationDetails } from '../../modules/presentation/components/PresentationDetails';
import { usePresentationId, useSlideId } from '../../modules/presentation/hooks/usePresentationId.ts';

interface Props {}

export const PresentationPage: React.FC<Props> = () => {
	const presentationId = usePresentationId();
	const slideId = useSlideId();

	return (
		<>
			<PresentationDetails presentationId={presentationId} slideId={slideId} />
		</>
	);
};
