import React from 'react';
import Picker from '@emoji-mart/react';
import { IconPickerData } from './IconPickerData.ts';

interface Props {
	onChange: (emoji: Icon) => void;
}

type Icon = {
	src: string;
};

type Emoji = {
	src: string;
};

export const IconPicker: React.FC<Props> = ({ onChange }) => {
	const handleOnEmojiSelect = (e: Emoji) => {
		onChange(e);
	};

	return (
		<Picker
			custom={IconPickerData}
			skinTonePosition='none'
			previewEmoji='atom'
			categories={['business', 'comunication', 'education', 'environment', 'social', 'technology']}
			onEmojiSelect={handleOnEmojiSelect}
			theme='light'
		/>
	);
};
