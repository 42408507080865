import axios, { AxiosResponse } from 'axios';

/**
 * // Usage
 * const unsplashClient = new UnsplashClient()
 * unsplashClient.searchPhotos({ query: 'mountain' })
 * 	.then(data => console.log(data))
 * 	.catch(error => console.error(error))
 */

const axiosClient = axios.create({
	baseURL: `${import.meta.env.VITE_API_BASE_URL}/image-api/unsplash`,
	timeout: 30000,
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		'X-API-KEY': import.meta.env.VITE_IMAGE_API_KEY,
		'X-Unsplash_Client_Id': 'AUTHOR_APP',
	},
});

export interface UnsplashSearchParams {
	query: string;
	page: number;
	per_page?: number;
	order_by?: 'latest' | 'relevant';
	collections?: string;
	content_filter?: 'low' | 'high';
	color?: 'black_and_white' | 'black' | 'white' | 'yellow' | 'orange' | 'red' | 'purple' | 'magenta' | 'green' | 'teal' | 'blue';
	orientation?: 'landscape' | 'portrait';
}

class UnsplashClient {
	async searchPhotos(params: UnsplashSearchParams): Promise<UnsplashResponse> {
		try {
			const response: AxiosResponse<UnsplashResponse> = await axiosClient.get(`/search/photos`, {
				params: {
					...params,
				},
			});

			return response.data;
		} catch (error) {
			if (axios.isAxiosError(error) && error.response) {
				console.error(error);
				// return error.response.data as UnsplashErrorResponse
				throw new Error('Unsplash error');
			} else {
				// return { errors: ['An unexpected error occurred'] }
				throw new Error('An unexpected error occurred with Unsplash api');
			}
		}
	}

	async trackPhotoDownload(params: UnsplashPhoto): Promise<void> {
		try {
			await axiosClient.get('download', {
				params: { downloadLocation: params.links.download_location },
			});
		} catch (error) {
			console.error(error); // Do nothing, in the future it should trigger an alert
		}
	}
}

export const unsplashClient = new UnsplashClient();

interface UnsplashUser {
	id: string;
	username: string;
	name: string;
	first_name: string;
	last_name: string | null;
	instagram_username: string | null;
	twitter_username: string | null;
	portfolio_url: string | null;
	profile_image: UnsplashUserProfileImage;
	links: UnsplashUserLinks;
}

interface UnsplashUserProfileImage {
	small: string;
	medium: string;
	large: string;
}

interface UnsplashUserLinks {
	self: string;
	html: string;
	photos: string;
	likes: string;
}

interface UnsplashPhotoUrls {
	raw: string;
	full: string;
	regular: string;
	small: string;
	thumb: string;
}

interface UnsplashPhotoLinks {
	self: string;
	html: string;
	download: string;
	download_location: string;
}

export interface UnsplashPhoto {
	id: string;
	created_at: string;
	width: number;
	height: number;
	color: string;
	blur_hash: string;
	likes: number;
	liked_by_user: boolean;
	description: string | null;
	user: UnsplashUser;
	current_user_collections: unknown[]; // Update this type if you have more specific information about the collections
	urls: UnsplashPhotoUrls;
	links: UnsplashPhotoLinks;
}

interface UnsplashResponse {
	total: number;
	total_pages: number;
	results: UnsplashPhoto[];
	errors: string[];
}
